<template>
  <div>

    <page-tabs page="settings" />

    <div class="row">

      <div class="col-sm-12 col-md-3">

        <div class="card mb-3">
          <div class="card-body">

            <p class="card-title">ADD LOCATION</p>

              <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name">Name</label>
                <input id="name" class="form-control" v-model="form.name" required>
              </div>

                <div class="form-group">
                  <fieldset>
                    <legend>Location Type</legend>
                    <label
                        class="mr-2 text-capitalize"
                        for="virtual"
                    >
                      <input
                          id="virtual"
                          v-model="form.virtual"
                          type="radio"
                          name="virtual"
                          value="1"
                      > Virtual
                    </label>
                    <label
                        class="mr-2 text-capitalize"
                        for="physical"
                    >
                      <input
                          id="physical"
                          v-model="form.virtual"
                          type="radio"
                          name="virtual"
                          value="0"
                      > Physical
                    </label>
                  </fieldset>
                </div>

              <div class="form-group" :hidden="form.virtual == 1">
                <label for="address">Address</label>
                <input id="address" class="form-control" v-model="form.address" autocomplete="street-address">
              </div>

              <div class="form-group" :hidden="form.virtual == 1">
                <label for="address_2">Address 2</label>
                <input id="address_2" class="form-control" v-model="form.address_2" autocomplete="address-line2">
              </div>

              <div class="form-group" :hidden="form.virtual == 1">
                <label for="city">City</label>
                <input id="city" class="form-control" name="city" v-model="form.city" autocomplete="address-level2">
              </div>

              <div class="form-group" :hidden="form.virtual == 1">
                <label for="county">County</label>
                <input id="county" class="form-control" name="county" v-model="form.county" autocomplete="address-level3">
              </div>

              <div class="form-group" :hidden="form.virtual == 1">
                <label for="state">State</label>
                <input id="state" class="form-control" v-model="form.state" autocomplete="address-level1">
              </div>

              <div class="form-group" :hidden="form.virtual == 1">
                <label for="zip">Zip</label>
                <input id="zip" class="form-control" v-model="form.zip" autocomplete="postal-code">
              </div>


              <button type="submit" class="btn btn-success" :disabled="processing">Add</button>

              </form>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">

            <p class="card-title">Search</p>

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">Search</label>
                <input id="search" type="search" class="form-control" v-model="queries.search">
              </div>

              <div class="form-group">
                <label for="search_name">Name</label>
                <input id="search_name" type="text" class="form-control" v-model="queries.name">
              </div>

              <div class="form-group">
                <label for="search_reference">Reference</label>
                <input id="search_reference" type="text" class="form-control" v-model="queries.reference">
              </div>

              <div class="form-group">
                <label for="search_locales">Agency</label>
                <input id="search_locales" type="text" class="form-control" v-model="queries.locales">
              </div>

              <div class="form-group">
                <label for="search_address">Address</label>
                <input id="search_address" type="search" class="form-control" v-model="queries.address">
              </div>

              <div class="form-group">
                <label for="search_created_by">Creator</label>
                <select 
                  id="search_created_by"
                  v-model="queries.created_by"
                  class="form-control" 
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="user in users">
                    <option 
                      :value="user.email"
                    >
                      {{ user.name }} {{ user.email }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="search_virtual"
                    v-model="queries.virtual"
                    type="checkbox"
                    class="custom-control-input"
                    @change="searchRecords"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="search_virtual"
                >Virtual</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="search_default"
                    v-model="queries.default"
                    type="checkbox"
                    class="custom-control-input"
                    @change="searchRecords"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="search_default"
                >Is Default</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="portal"
                  v-model="queries.portal"
                  type="checkbox"
                  class="custom-control-input"
                  @change="searchRecords"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="portal"
                >Portal</label>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="search_archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="custom-control-input"
                      @change="searchRecords"
                      :disabled="processing"
                    >
                    <label
                      class="custom-control-label"
                      for="search_archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="search_trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="custom-control-input"
                      @change="searchRecords"
                      :disabled="processing"
                    >
                    <label
                      class="custom-control-label"
                      for="search_trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <locations-upload class="my-3" />

        <locations-generate class="my-3" />

      </div><!--Col-->

      <div id="main" class="col-sm-12 col-md-9">

        <div
            id="map"
            class="map-xl mb-3"
        />

            <template v-if="default_location && default_location.uuid">
              <div class="alert alert-info">
                Default location: <a :href="'/locations/'+default_location.uuid" class="alert-link">{{ default_location.name }}</a>
              </div>
            </template>

            <template v-if="!default_location.uuid && records.length">
              <div class="alert alert-warning">
                No default location set.
              </div>
            </template>

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                  <p class="card-title">
                    {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
                  </p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">
                <div class="btn-group d-print-none">
                <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->
            </div><!--Row-->

            <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <thead>
                  <template v-for="column in columns">
                    <th>
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </thead>
                <tbody>
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <a :href="'/locations/'+record.uuid">
                          <i class="fa fa-edit"></i> Edit
                        </a>
                      </td>
                      <td>
                          {{ record.name }}
                      </td>
                      <td>{{ record.location_reference }}
                      <template v-if="record.locales">
                        <span class="badge badge-pill badge-secondary">{{ record.locales }}</span>
                      </template>
                      </td>
                      <td>
                        <template v-if="record.default">
                          <i class="fa fa-check-circle text-success"></i>
                        </template>
                        <template v-if="!record.default">
                          <i class="fa fa-times-circle text-danger"></i>
                        </template>
                      </td>

                      <td>
                        <template v-if="record.portal">
                          <i class="fa fa-check-circle text-success"></i>
                        </template>
                        <template v-if="!record.portal">
                          <i class="fa fa-times-circle text-danger"></i>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.virtual">
                          <i class="fa fa-check-circle text-success"></i>
                        </template>
                        <template v-if="!record.virtual">
                          <i class="fa fa-times-circle text-danger"></i>
                        </template>
                      </td>
                      <td>
                          {{ record.address }} {{ record.address_2 }} {{ record.city }} {{ record.state }} {{ record.zip }}
                      </td>
                      <td>
                        <template v-if="record.phone">
                          <a :href="'tel:'+record.phone" target="_blank">{{ record.phone }}</a>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.email">
                          <a :href="'mailto:'+record.email" target="_blank">{{ record.email }}</a>
                        </template>
                      </td>
                      <td>{{ record.website }}</td>
                      <td>{{ record.created_by }}</td>
                      <td>{{ record.created_at | datetime }}
                        <template v-if="record.deleted_at">
                        <span class="badge badge-pill badge-danger">
                          Trashed
                        </span>
                        </template>
                        <template v-if="record.archived_at">
                          <span class="badge badge-pill badge-warning">
                            Archived
                          </span>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.updated_at">
                          {{ record.updated_at | datetime }}
                        </template>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div><!--Responsive-->

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords()"
              >
                Load more
              </button>
            </div>
          </div><!--Row-->
        </template>

      </div><!--Col-->

    </div><!--Row-->

  </div>
</template>
<script>
import { autofill } from '@mapbox/search-js-web';
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data() {
        return {
            records: [],
            processing: true,
            total: 0,
            queries: {},
            form: {},
            columns: [
            { label: 'Edit'},
            { key: 'name', label: 'Name'},
            { key: 'location_reference', label: 'Reference'},
            { key: 'default', label: 'Default'},
            { key: 'portal', label: 'Portal'},
            { key: 'virtual', label: 'Virtual'},
            { key: 'address', label: 'Address'},
            { key: 'phone', label: 'Phone'},
            { key: 'email', label: 'Email'},
            { key: 'website', label: 'Website'},
            { key: 'created_by', label: 'Creator'},
            { key: 'created_at', label: 'Created'},
            { key: 'updated_at', label: 'Updated'}
            ],
            users: [],
            autofill: {},
            downloading: false,
            downloads: [],
            map: {},
            markers: {},
            default_location: {}
        }
    },

    mounted() {

        this.initMap();

        var self = this;

        this.$eventBus.$on("newLocation", function (record) {
              self.pushRecord(record);
        });

        this.autoFill();

    },

    created() {
      this.resetForm();
      this.resetRecords();
      this.resetQueries();
      this.setURLParams();
      this.getRecords();
      this.getUsers();
      this.getDefault();
    },

    methods: {

        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);

            var self = this;

            searchParams.forEach(function(value, key) {
                self.queries[key] = value;
            });

        },

        resetForm() {
          this.form = {
            name: null,
            address: null,
            address_2: null,
            city: null,
            state: null,
            zip: null,
            county: null,
            country: null,
            lat: null,
            lng: null,
            portal: 1,
            virtual: 1,
            default: 0
          }
        },

        resetQueries() {
          this.queries = {
              limit: 100,
              skip: 0,
              created_by: null,
              search: null,
              name: null,
              reference: null,
              address: null,
              portal: false,
              virtual: null,
              locales: null,
              trashed: false,
              archived: false,
              default: null,
              slim: true,
              sort: 'name',
              sortDirection: 'asc'
          }
        },

        autoFill() {

          var options = { language: 'en', country: 'us' };

          if(this.settings && this.settings['country']) {
            options['country'] = this.settings['country'];
          }

          if(this.settings && this.settings['latitude'] && this.settings['longitude']) {
            options['proximity'] = [this.settings['longitude'], this.settings['latitude']];
          }

          this.autofill = new autofill({
            accessToken: this.$root.mapbox_token,
            options: options
          });

          var self = this;
 
          this.autofill.addEventListener('retrieve', (event) => {

            if (!event.detail || !event.detail.features || !event.detail.features.length) {
              return;            
            }

            const features = event.detail.features[0];

            if(features['text_en'] && !self.form.name) {
              self.form.name = event.detail.features[0]['text_en'];
            }

            if(features['geometry'] && features['geometry']['coordinates']) {
              self.form.lat = features['geometry']['coordinates'][1] || null;
              self.form.lng = features['geometry']['coordinates'][0] || null;
            }

            if(!features['properties']) {
              return;
            }

            if(features['properties']['district']) {
              self.form.county = features['properties']['district'];
            }

            if(features['properties']['country_code']) {
              self.form.country = features['properties']['country_code'];
            }

            if(features['properties']['address_line2']) {
                self.form.address_2 = features['properties']['address_line2'];
            }

            if(features['properties']['city']) {
              self.form.city = features['properties']['city'];
            }

            if(features['properties']['address_level1']) {
               self.form.state = features['properties']['address_level1'];
            }

            if(features['properties']['postcode']) {
                self.form.zip = features['properties']['postcode'];
            }

            if(features['properties']['country']) {
              self.form.country = features['properties']['country'];
            }

          });

        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status === 200) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
        },

        getDefault() {
          this.default_location = {};
          this.$http.get('/locations?default=1&sort=created_at&sortDirection=desc&limit=1&fields=name,uuid')
          .then(response => {
            if(response && response.data && response.data.length) {
              this.default_location = response.data[0];
            }
          })
        },

        getRecords() {

            this.processing = true;

            this.updateURL();
            this.getTotal();

            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if(response && response.data) {
                    if(response.data.length) {

                      if(!this.records || !this.records.length) {
                        this.records = [];
                      }

                      var self = this;

                        response.data.forEach(function(data) {
                            self.records.push(data);
                            self.setMarker(data);
                        });
                    }

                    this.queries.skip = this.records.length;
                    this.processing = false;
                }
            })
        },

        resetRecords() {
          this.records = [];
          this.queries.skip = 0;
          this.total = 0;
          this.downloads = [];
        },

        searchRecords() {
            this.resetRecords();
            this.getRecords();
        },

        updateSortDirection() {

          if(this.queries.sortDirection == 'asc') {
            this.queries.sortDirection = 'desc';
          } else {
            this.queries.sortDirection = 'asc';
          }

          this.resetRecords();
          this.getRecords();
        },

        sortColumn(column) {

          this.queries.sort = column.key;

          this.updateSortDirection();
        },

        getUsers() {
          this.$http.get('/users?slim=true&limit=1000&fields=name,email&sort=name&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.users = response.data;
            }
          })
        },

        postForm() {
          this.processing = true;
          this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.data) {

              this.resetForm();

              this.processing = false;

              if(response.status === 201) {
                this.$router.push('/'+this.$route.meta.base_url+'/'+response.data.uuid);
              }

            }
          })
        },

        pushRecord(data)
        {
            new this.$noty({text: this.$route.meta.title + ' added'}).show();
            this.total++;
            this.records.unshift(data);
            this.setMarker(data);
        },

      setMarker(record) {

          if(!this.markers) {
            return;
          }

        if(!record.lat || !record.lng) {
          return;
        }

        var popup = '<p class="m-0">Location <a href='+'/locations/'+record.uuid+'>'+record.name+'</a></p>';

        if(record.default === 1) {
          popup = popup + ' <br><span class="badge badge-success">DEFAULT</span>';
        }

        if(record.phone) {
          popup = popup + '<br><i class="fa fa-phone"></i> '+record.phone;
        }

        if(record.address) {
          popup = popup + '<br><i class="fa fa-map-marker-alt"></i> '+record.address;
        }

        const courtIcon = window.L.divIcon({
          html: '<i class="fa fa-building-columns text-primary fa-2x"></i>',
          iconSize: [20, 20],
          className: 'map-icon'
        });

        var marker = window.L.marker([record.lat, record.lng], {icon: courtIcon}).bindPopup(popup);

        if(this.markers) {
          this.markers.addLayer(marker);
        }

      },

      initMap() {

        this.map = window.L.map("map").setView(this.$root.coordinates, 4);

        window.L.tileLayer(this.$root.map_layer).addTo(this.map);

        this.markers = window.L.markerClusterGroup({
          spiderfyOnMaxZoom: true,
          showCoverageOnHover: true,
          zoomToBoundsOnClick: true
        });

        this.map.addLayer(this.markers);

      }

    }
}

</script>