<template>
    <div>
  
        <page-tabs :page="$route.meta.tabs" />

        <div class="row">
            <div class="col-md-6 mx-auto">


                    <div class="card">
                        <div class="card-body">

                            <h5 class="card-title">Request an Interpreter</h5>

                            <form @submit.prevent="postForm">

                                <div class="form-group">
                                    <label for="title">Task Title</label>
                                    <input type="text" name="title" id="title" class="form-control" v-model="form.title" required>
                                </div>

                            <div class="form-group">
                                <label for="language">Language *</label>
                                <select name="language" id="language" class="form-control" v-model="form.language" required>
                                    <template v-for="language in languages">
                                        <template v-if="language.key != 'en'">
                                            <option :value="language.key">{{ language.label }}</option>
                                        </template>
                                    </template>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="location">Location</label>
                                <select name="location" id="location" class="form-control" v-model="form.location">
                                    <option value="">Select a Location</option>
                                    <template v-for="location in locations">
                                        <option :value="location.uuid">{{ location.name }}</option>
                                    </template>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="due_at">Due Date</label>
                                <input type="date" name="due_at" id="due_at" class="form-control" v-model="form.due_at">
                            </div>

                            <div class="form-group custom-control custom-switch">
                                <input
                                id="virtual"
                                v-model="form.virtual"
                                type="checkbox"
                                class="custom-control-input"
                                >
                                <label
                                class="custom-control-label"
                                for="virtual"
                                >Allow Virtual</label>
                            </div>

                            <p>If an interpreter is found, you can review the fee and approve the interpreter. There is no cost if you do not approve the interpreter.</p>

                            <button type="submit" class="btn btn-success" :disabled="processing">Request Interpreter</button>

                            </form>

                        </div><!--Body-->
                    </div><!--Card-->


                    <div class="card mt-3">
                        <div class="card-body">

                            <h5 class="card-title">{{ tasks.length }} Tasks</h5>

                            <template v-for="task in tasks">
                                <li class="list-group-item">
                                    {{ task.title }}

                                    <template v-if="task.language">
                                        <small class="text-muted">{{ setLanguage(task.language) }}</small>
                                    </template>

                                    <template v-if="task.due_at">
                                        <span class="badge badge-danger ml-2">Due {{ task.due_at | date }}</span>
                                    </template>
                                </li>
                            </template>

                        </div>
                    </div>

            </div><!--Col-->

        </div><!--Row-->

    </div>
  </template>
  
  <script>
  import queries from '../mixins/queries';
  
  export default {
  
    mixins: [ queries ],
  
  data() {
      return {
          processing: false,
          form: {},
          locations: [],
          tasks: []
      }
  },
  
  created() {
      document.title = this.$route.meta.title + ' | eCourtDate.com';

      this.resetForm();

      this.setURLParams();

      this.getLanguages();
      this.getLocations();
      this.getTasks();
  },
  
  methods: {

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {

            if(!value || value == 'null') {
              return;
            }

            self.form[key] = value;
        });
    },

    resetForm() {
        this.form = {
            language: 'es',
            location: '',
            due_date: '',
            files: [],
            virtual: 1,
            client: null,
            message: null,
            event: null,
            notes: ''
        }

        if(!this.form.due_at) {
            this.form.due_at = this.$moment().add(1, 'day').format('YYYY-MM-DD');
        }

    },

    getLocations() {
        this.$http.get('/locations?limit=100&fields=name,uuid&sort=default&sortDirection=desc')
        .then(response => {
            if(response && response.data) {
                this.locations = response.data;

                if(!this.form.location && this.locations.length > 0) {
                    this.form.location = this.locations[0].uuid;
                }
            }
        });
    },

    postForm() {
        this.processing = true;

        this.$http.post('/interpreters/tasks', this.form)
        .then(response => {
            if(response && response.data) {

                if(!this.tasks.length) {
                    this.tasks = [];
                }

                this.tasks.unshift(response.data);
            }
            this.processing = false;
        });
    },


    getTasks() {
        this.processing = true;
        this.$http.get('/interpreters/tasks?limit=100&fields=name,uuid&sort=default&sortDirection=desc')
        .then(response => {
            if(response && response.data) {
                this.tasks = response.data;
            }
            this.processing = false;
        });
    },

    
  }
  
  }
  </script>