<template>
  <div>
    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="title"
    />
    <div class="row my-3">
      <div class="col-sm-12 col-md-4">
        <h1 id="date_time">
          <template v-if="form && form.date">
            {{ $options.filters.dateDay(form.date, form.timezone) }} {{ form.date | date }} {{ form.time | time }}
          </template>
        </h1>
        <template v-if="form && form.date && form.time">
          <span class="badge badge-pill badge-secondary mr-1 text-uppercase">{{ setDateDiff(form) }}</span>
        </template>
        <template v-if="form.event_reference">
          <span class="badge badge-pill badge-secondary link-hover mr-1" @click="copyToClipboard(form.event_reference)">Event Ref {{ form.event_reference }}</span>
        </template>
        <template v-if="form.case_number">
          <span class="badge badge-pill badge-secondary link-hover" @click="copyToClipboard(form.case_number)">Case Number {{ form.case_number }}</span>
        </template>
        <template v-if="form.reached && form.reached == 1">
          <span id="reached" class="badge badge-pill badge-success">REACHED</span>
        </template>

        <template v-if="form.reached && form.reached != 1 && unreachables && unreachables[form.reached]">
          <span id="unreachable" class="badge badge-pill badge-danger" :hidden="form.reached == 2">{{ unreachables[form.reached] }}</span>
        </template>
        <p class="my-1 text-capitalize"><router-link :to="{name: $route.meta.base_url+'.index'}">{{ title }}s</router-link> / Edit {{ title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-2">

        <div class="btn-group">
            <div class="dropdown">
              <button
                id="dropdownMenuButton"
                class="btn btn-outline-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="getCalendars()"
                :disabled="processing"
              >
                <i class="fa fa-calendar"></i> Add to Calendar
              </button>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >

              <a class="dropdown-item"
              @click="saveICS()"
              target="_blank">
                iCalendar <small>ics</small>
              </a>

                <template v-for="calendar_link in calendar_links">
                  <a
                    class="dropdown-item text-capitalize"
                    :href="calendar_link.link"
                    target="_blank"
                  >{{ calendar_link.title }}</a>
                </template>
              </div>
            </div>
        </div>

        <template v-if="form.url && form.url.includes('http')">
            <a :href="form.url" target="_blank" class="btn btn-secondary-outline" :disabled="processing"><i class="fa fa-link"></i> Event Link</a>
        </template>

        <template v-if="cmsURL">
            <a :href="cmsURL" target="_blank" class="btn btn-secondary-outline" :disabled="processing"><i class="fa fa-link"></i> CMS</a>
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-2">
        <template v-if="form.url && !form.portal">
            <portal-links :url="'e/'+form.url"></portal-links>
        </template>

        <template v-if="form.url && form.portal">
          <portal-link :url="'e/'+form.url" :uuid="form.portal" />
        </template>
        
      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">

          <button type="button" class="btn btn-secondary" @click="getPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>

          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="processing"
          >
            Tools
          </button>
          <div class="dropdown-menu">
            <a
              v-if="!form.archived_at && !form.deleted_at"
              class="dropdown-item"
              @click="syncRecord"
            >Sync Messages</a>
            <a
                v-if="!form.archived_at && !form.deleted_at"
                class="dropdown-item"
                @click="duplicateRecord"
            >Duplicate</a>
            <a
              v-if="!form.archived_at && !form.deleted_at"
              class="dropdown-item"
              @click="archiveRecord"
            >Archive</a>
            <a
              v-if="form.deleted_at || form.archived_at"
              class="dropdown-item"
              @click="restoreRecord"
            >Restore</a>
            <a
              v-if="!form.deleted_at && !form.archived_at"
              class="dropdown-item"
              @click="trashRecord"
            >Trash</a>
          </div>

          <button
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div><!--BtnGroup-->
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
          <div class="card-body">

            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <p class="card-title">
                      Edit {{ title }}
                    </p>
                </div><!--Col-->
                <div class="col-sm-12 col-md-12 text-right">
                    <template v-if="form.status">
                        <disable-status-button :status="form.status"></disable-status-button>
                    </template>
                </div><!--Col-->
            </div><!--Row-->

    <form @submit.prevent="postForm">

      <div class="row">

        <div class="col-sm-12 col-md-6">

          <div class="form-group">
            <label for="date" class="form-label">{{ setCustomField('events', 'date') }}</label>
            <input
              id="date"
              v-model="form.date"
              type="date"
              class="form-control"
            >
          </div>

        </div><!--Col-->

        <div class="col-sm-12 col-md-6">

          <div class="form-group">
            <label for="time">{{ setCustomField('events', 'time')}}</label>
            <input
              id="time"
              v-model="form.time"
              type="time"
              class="form-control"
              step="60"
            >
          </div>

        </div><!--Col-->

      </div><!--Row-->

      <div class="row">
        <div class="col-sm-12 col-md-6">

          <div class="form-group">
            <label for="end_date" class="form-label">{{ setCustomField('events', 'end_date') }}</label>
            <input id="end_date" type="date" class="form-control" v-model="form.end_date">
          </div>


        </div><!--Col-->

        <div class="col-sm-12 col-md-6">

          <div class="form-group">
            <label for="end_time">{{ setCustomField('events', 'end_time')}}</label>
            <input
              id="end_time"
              v-model="form.end_time"
              type="time"
              class="form-control"
              step="60"
            >
          </div>

        </div><!--Col-->

      </div><!--Row-->


          <div class="form-group" v-if="shouldShowField('event_reference')">
            <label for="event_reference">{{ setCustomField('events', 'event_reference')}}</label>
            <input
              id="event_reference"
              v-model="form.event_reference"
              type="text"
              class="form-control"
            >
          </div>


          <div class="form-group">
                <label for="multi_case_number">{{ setCustomField('events', 'case_number') }}</label>
                <multi-select 
                  id="multi_case_number"
                  v-model="form.case_number" 
                  :options="cases_results"
                  placeholder="Search case numbers"
                  :multiple="false"
                  :searchable="true"
                  :internal-search="false"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :show-no-results="true"
                  :hide-selected="false"
                  @search-change="searchCaseNumbers"
                >
                </multi-select>
          </div>

          <p class="text-muted mt-0 mb-3">
            <i class="fa fa-file-alt"></i>
            <a href="/cases" target="_blank"> Manage cases</a>
          </p>


          <div class="form-group" v-if="shouldShowField('flow')">
        <label for="flow">{{ setCustomField('events', 'flow')}}

        <template v-if="form.flow">
          <a
          :href="'/flows/'+form.flow"
          target="_blank"
          data-tooltip="Edit Flow">
          <i class="fa fa-edit"></i> <span class="sr-only">Edit Flow</span>
          </a>
        </template>

        </label>
        <select
          id="flow"
          v-model="form.flow"
          class="form-control"
          :disabled="processing"
        >
          <option value="" />
          <template v-for="flow in flows">
            <option
              :value="flow.uuid"
            >
              {{ flow.name }}
            </option>
          </template>
        </select>
      </div>

          <div class="form-group" v-if="shouldShowField('portal')">
        <label for="portal">{{ setCustomField('events', 'portal')}}

        <template v-if="form.portal">
          <a
          :href="'/portals/'+form.portal"
          target="_blank"
          data-tooltip="Edit Portal">
          <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
          </a>
        </template>

        </label>
        <select
          id="portal"
          v-model="form.portal"
          class="form-control"
          :disabled="processing"
        >
          <option value="" />
          <template v-for="portal in portals">
            <option
              :value="portal.uuid"
            >
              {{ portal.name }}
            </option>
          </template>
        </select>
      </div>


      <div class="form-group" v-if="shouldShowField('location')">
        <label for="location">{{ setCustomField('events', 'location')}}

        <template v-if="form.location">
          <a
          :href="'/locations/'+form.location"
          target="_blank"
          data-tooltip="Edit Location">
          <i class="fa fa-edit"></i> <span class="sr-only">Edit Location</span>
          </a>
        </template>

        </label>
        <select
          id="location"
          v-model="form.location"
          class="form-control"
          :disabled="processing"
        >
          <option value="" />
          <template v-for="location in locations">
            <option
              :value="location.uuid"
            >
              {{ location.name }}
            </option>
          </template>
        </select>
      </div>


      <template v-if="locationRooms.length > 0">
        <div class="form-group">
          <label for="room">{{ setCustomField('events', 'room') }}</label>
          <select id="room" v-model="form.room" class="form-control" :disabled="processing" @change="setLocationRoom()">
            <option value="" />
            <template v-for="room in locationRooms">
              <option :value="room.uuid">{{ room.name }}</option>
            </template>
          </select>
        </div>
      </template>


      <div class="form-group" v-if="shouldShowField('location_notes')">
        <label for="location_notes">{{ setCustomField('events', 'location_notes')}}</label>
        <input
          id="location_notes"
          v-model="form.location_notes"
          type="text"
          class="form-control"
          rows="3"
        >
    </div>

    <div class="form-group custom-control custom-switch">
        <input
          id="virtual"
          v-model="form.virtual"
          type="checkbox"
          class="custom-control-input"
          :disabled="processing"
        >
        <label
          class="custom-control-label"
          for="virtual"
        >IS VIRTUAL</label>
      </div>

      <template v-if="form.virtual">
        <div class="form-group">
            <label for="virtual_notes">{{ setCustomField('events', 'virtual_notes')}}</label>
            <textarea
              id="virtual_notes"
              v-model="form.virtual_notes"
              class="form-control"
              rows="3"
            />
        </div>
      </template>

    <div class="form-group" v-if="shouldShowField('judge_name')">
        <label for="judge_name">{{ setCustomField('events', 'judge_name') }}</label>
        <select
          id="judge_name"
          v-model="form.judge_name"
          class="form-control"
          :disabled="processing"
        >
          <option value="" />
          <option value="new_judge">Add a new Judge</option>
          <template v-for="judge in judges">
            <option
              :value="judge.name"
            >
              {{ judge.name }}
            </option>
          </template>
        </select>

        <template v-if="form.judge_name == 'new_judge'">

          <input id="event_judge" type="text" class="form-control my-3" placeholder="New Judge Name" v-model="new_judge" required>

        </template>

      </div>


      <div class="form-group" v-if="shouldShowField('type')">
        <label for="type">{{ setCustomField('events', 'type') }}
          <button type="button" class="btn btn-sm m-0 p-0" @click="showNewType"><i class="fa fa-plus"></i> <span class="sr-only">Add new</span></button>
        </label>
        <template v-if="!show_new_type">
        <select
          id="type"
          class="form-control"
          v-model="form.type"
          :disabled="processing">
        <option value=""></option>
        <template v-for="event_type in event_types">
          <option :value="event_type.type">{{ event_type.type }}</option>
        </template>
        </select>
        </template>

        <template v-if="show_new_type">

          <input id="event_type" type="text" class="form-control" v-model="form.type">

        </template>

      </div>

      <div class="form-group" v-if="shouldShowField('description')">
        <label for="description">{{ setCustomField('events', 'description')}}</label>
        <input
          id="description"
          v-model="form.description"
          type="text"
          class="form-control"
        >
      </div>


          <div class="form-group" v-if="shouldShowField('status')">
            <label for="status">{{ setCustomField('events', 'status') }}

              <template v-if="form.status">
                <a
                    :href="'/statuses?name='+form.status"
                    target="_blank"
                    data-tooltip="Edit Status">
                  <i class="fa fa-edit"></i> <span class="sr-only">Edit Status</span>
                </a>
              </template>

            </label>
            <select
              id="status"
              v-model="form.status"
              class="form-control"
              :disabled="processing"
            >
              <option value="" />
              <template v-for="status in statuses">
                <option
                  :value="status.name"
                >
                  {{ status.name }}
                </option>
              </template>
            </select>
            <template v-if="form.status">
                <p class="form-text text-muted">{{ form.status }}</p>              
            </template>

          </div>

            <div class="form-group">
              <label for="appeared">{{ setCustomField('events', 'appeared') }}

                <template v-if="form.appeared">
                  {{ form.appeared }}
                </template>

              </label>
              <select
                  id="appeared"
                  v-model="form.appeared"
                  class="form-control"
                  :disabled="processing"
              >
                <option value="" />
                <template v-for="appeared in appeareds">
                  <option
                    :value="appeared.name"
                  >
                    {{ appeared.name }}
                  </option>
                </template>
              </select>

              <template v-if="form.appeared == 'FTA'">
                <small class="text-danger">Failure to Appear</small>
              </template>

            </div>

            <div class="form-group" v-if="shouldShowField('judge_name')">
              <label for="judge_name">{{ setCustomField('events', 'judge_name') }}</label>
              <select
                id="judge_name"
                v-model="form.judge_name"
                class="form-control"
                :disabled="processing"
              >
                <option value="" />
                <template v-for="judge in judges">
                  <option :value="judge.name">{{ judge.name }}</option>
                </template>
              </select>
            </div>


      <div class="form-group">
        <label for="internal_notes">{{ setCustomField('events', 'internal_notes') }}</label>
        <textarea
          id="internal_notes"
          v-model="form.notes"
          class="form-control"
          rows="3"
        />
      </div>

      <div class="form-group">
        <label for="url">{{ setCustomField('events', 'url') }}</label>
        <input id="url" class="form-control" type="text" v-model="form.url">
      </div>

      <button
        type="submit"
        class="btn btn-success"
        :disabled="processing"
      >
        <template v-if="form && form.uuid">
          Save
        </template>
        <template v-else>
          Add
        </template>
      </button>
    </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs
          :form="form"
          class="mt-3"
        />
      </div><!--Col-->
      <div class="col-sm-12 col-md-6 col-lg-4">

        <template v-if="event_case && event_case.uuid">

        <div class="card mb-3">
          <div class="card-body">

          <div class="row">

            <div class="col-sm-12 col-md-6">
              <p class="card-title">Case Details</p>
            </div><!--Col-->

            <div class="col-sm-12 col-md-6 text-right">
              <router-link
                tag="button"
                class="btn btn-sm btn-outline-primary"
                :to="{name: 'cases.edit', params: {id: event_case.uuid}}"
              >
                <i class="fa fa-edit"></i> Edit Case
              </router-link>
            </div><!--Col-->

          </div><!--Row-->

          <p class="m-0 fw-bold">Case Number</p>
          <p class="m-0 fw-bold" id="case_number">{{ event_case.case_number }}</p>

          <template v-if="event_case.type">
            <li class="list-group-item">Type {{ event_case.type }}</li>
          </template>

          <template v-if="event_case.status">
            <li class="list-group-item">Status {{ event_case.status }}</li>
          </template>

          <template v-if="event_case.description">
            <li class="list-group-item">Description {{ event_case.description }}</li>
          </template>

          <template v-if="event_case.arresting_agency">
            <li class="list-group-item">Arresting Agency {{ event_case.arresting_agency }}</li>
          </template>

          <template v-if="event_case.arrest_tracking">
            <li class="list-group-item">Arrest Tracking {{ event_case.arrest_tracking }}</li>
          </template>

          <template v-if="event_case.offense_description">
            <li class="list-group-item">Offense Description {{ event_case.offense_description }}</li>
          </template>

          <template v-if="event_case.offender_name">
            <li class="list-group-item">Offender Name {{ event_case.offender_name }}</li>
          </template>

          <template v-if="event_case.attorney && event_case.attorney.uuid">

            <template v-if="event_case.attorney.bar_number">
              <li class="list-group-item">Bar Number {{ event_case.attorney.bar_number }}</li>
            </template>

            <template v-if="event_case.attorney.firm_name">
              <li class="list-group-item">Attorney {{ event_case.attorney.firm_name }}</li>
            </template>

          </template>


          </div><!--Body-->
        </div><!--Card-->

          </template>

            <template v-if="form.location">
              <location-card :uuid="form.location" />
            </template><!--Location-->

            <template v-if="bonds && bonds.length > 0">
              <div class="card mt-3">
                <div class="card-body">
                  <p class="card-title">
                    <a :href="'/bonds?case_number='+caseSearch">{{ bonds.length }} Bonds</a>
                  </p>

                  <ul class="list-group">
                    <template v-for="bond in bonds">
                      <router-link
                        tag="li"
                        class="list-group-item list-group-item-action link-hover"
                        :to="{'name': 'bonds.edit', 'params': {id: bond.uuid }}"
                      >
                        {{ bond.bond_number }} {{ bond.collateral_type }} {{ bond.status }}
                      </router-link>
                    </template>
                  </ul>
                </div><!--Body-->
              </div><!--Card-->
            </template><!--Bonds-->

     </div><!--Col-->

          <div class="col-sm-12 col-md-6 col-lg-4">

            <template v-if="!form.client_uuid">
              <div class="alert alert-warning">
                Primary client not assigned.
              </div>
            </template>

            <form @submit.prevent="searchAttachableClients">
      <div class="input-group mb-3">
        <input
          v-model="search"
          type="text"
          class="form-control text-lowercase"
          aria-label="Client"
          aria-describedby="client"
        >
        <div class="input-group-append">
          <button
            id="client"
            class="btn btn-primary"
            type="submit"
            :disabled="processing"
          >
            Search Clients
          </button>
        </div>
      </div>

      <template v-if="records && records.length > 0">
        <ul class="list-group mb-3">
          <template v-for="record in records">

            <template v-if="!isAttached(record.uuid)">

            <li
              class="list-group-item"
            >
              <a :href="'/clients/'+record.uuid" target="_blank">{{ record.result }}</a>

              <template v-if="record.contacts">
                <br><small class="text-muted">{{ record.contacts }}</small>
              </template>

              <button type="button" class="btn btn-success float-right" @click="attachRecord(record)" :disabled="processing">Attach</button>
            </li>
            </template>
          </template>
        </ul>
              </template>
            </form>

            <template v-if="clients && clients.length > 0">
              <template v-for="client_attach in clients">

                <template v-if="client_attach.client != form.client_uuid">

                <client-button :uuid="client_attach.client" />

                <div class="d-flex justify-content-between mb-3">
                <button type="button" class="btn btn-outline-danger btn-sm" @click="detachRecord(client_attach.client)" :disabled="processing">Detach</button>
                <template v-if="client_attach.client != form.client_uuid">
                  <button type="button" class="btn btn-outline-primary btn-sm" @click="makePrimary(client_attach.client)" :disabled="processing">Make Primary</button>
                </template>
                </div>

                </template>

              </template>
            </template>


            <template v-if="form && form.client && form.client.uuid">

              <client-card :client="form.client.uuid" class="my-3" />

              <button type="button" class="btn btn-outline-danger btn-sm" @click="removePrimary(form.client.uuid)" :disabled="processing">Detach Primary Client</button>

              <client-contacts :client_uuid="form.client.uuid" class="my-3" />

            </template>


            <template v-if="form && form.uuid">

              <messages-form
                :client="form.client"
                :event="form.uuid"
                class="mb-3"
              />

              <messages-scheduled
                :event_uuid="form.uuid"
                class="mb-3"
              />
              <messages-feed :event_uuid="form.uuid" />

            </template>

          </div><!--Col-->
        </div><!--Row-->


  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

mixins: [ queries ],

    data () {
        return {
            processing: false,
            searching: false,
            downloading: false,
            search: '',
            title: null,
            form: {},
            client: {},
            messages: [],
            total_messages: 0,
            locations: [],
            rooms: [],
            portals: [],
            flows: [],
            statuses: [],
            appeareds: [],
            bonds: [],
            event_case: {},
            event_status: {},
            calendar_links: [],
            event_types: [],
            judges: [],
            records: [],
            default_queries: {
                slim: true,
                limit: 1000,
                sort: 'name',
                sortDirection: 'desc',
                fields: 'name,uuid'
            },
            show_new_type: false,
            new_judge: null,
            clients: [],
            unreachables: {},
            search_cases: null,
            cases_results: [],
            fields: [],
            availableFields: [
              {title: 'Start Date', key: 'date', type: 'date', show: true},
              {title: 'End Date', key: 'end_date', type: 'date', show: true},
              {title: 'Start Time', key: 'time', type: 'time', show: true},
              {title: 'End Time', key: 'end_time', type: 'time', show: true},
              {title: 'Event Reference', key: 'event_reference', type: 'text', show: true},
              {title: 'Case Number', key: 'case_number', type: 'text', show: true},
              {title: 'Status', key: 'status', type: 'list', show: true},
              {title: 'Virtual', key: 'virtual', type: 'checkbox', show: true},
              {title: 'Flow', key: 'flow', type: 'list', show: true},
              {title: 'Location', key: 'location', type: 'list', show: true},
              {title: 'Location Notes', key: 'location_notes', type: 'longtext', show: true},
              {title: 'Portal', key: 'portal', type: 'list', show: true},
              {title: 'Judge', key: 'judge_name', type: 'text', show: true},
              {title: 'Description', key: 'description', type: 'longtext', show: true},
              {title: 'Type', key: 'type', type: 'list', show: true}
            ],
        }
    },
    computed: {

        caseSearch: function() {
            return this.form.case_number || this.form.event_reference || null;
        },

        cmsURL: function() {
            if(this.settings) {
                if(this.settings.cms_url && this.caseSearch) {
                    return this.settings.cms_url + this.caseSearch;
                }
            }

            return false;
        },

        settings: function() {
            if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.settings) {
                return this.$root.current_user.current_agency.settings;
            }

            return false;
        },

        locationRooms: function() {
          if(!this.form.location) {
            return [];
          }
          return this.rooms.filter(room => room.location == this.form.location);
        }

    },
    
mounted() {

    var self = this;

    this.$eventBus.$on('preferences_updated', function() {
      self.setEventFields();
    });

    this.setEventFields();
},

    created () {

        this.resetForm();

        this.title = this.setCustomLabel(this.$route.meta.title);

        this.getRecord();

        this.getUnreachables();
        this.getFlows();
        this.getLocations();
        this.getRooms();
        this.getPortals();
        this.getStatuses();
        this.getEventTypes();
    },

    methods: {

      setEventFields() {
        if(this.$root.preferences && this.$root.preferences.event_fields) {
          this.fields = this.$root.preferences.event_fields;
        } else {
          this.fields = this.availableFields;
        }
      },

      shouldShowField(field) {

      if(!this.fields || !this.fields.length) {
        return true;
      }

      var activeField = _.find(this.fields, { 'key': field });

        return activeField && activeField.show;
      },

        getRecord () {

            this.processing = true;

            this.$http.get('/'+this.$route.meta.base_url + '/' + this.$route.params.id)
            .then(response => {
                if (response && response.data) {

                    this.form = response.data;

                    this.setTitle();

                    if(this.form.uuid) {
                        this.getStatus(this.form.uuid);
                    }

                    this.getEventCase();

                    this.getAttached();

                    this.getBonds();

                    // this.getCalendars();

                    this.getJudges();

                    this.processing = false;
                }
            })
        },

        getPDF() {
          this.processing = true;
          this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/pdf', {responseType: 'blob'})
          .then(response => {
            if(response && response.data) {
              this.processing = true;

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', this.$route.meta.base_url+'-'+this.form.uuid+'.pdf');
              document.body.appendChild(link);
              link.click();

              link.parentNode.removeChild(link);
              window.URL.revokeObjectURL(url);

              this.processing = false;
            }
          })
        },

        setTitle() {
            var title = this.form.event_reference || this.form.case_number || 'Edit Event';

            document.title = title + ' | eCourtDate.com';
        },

        getBonds() {

            if(!this.caseSearch) {
                return;
            }

            this.$http.get('/bonds?case_number='+this.caseSearch)
            .then(response => {
                if(response && response.data) {
                    this.bonds = response.data;
                }
            })
        },

        searchCaseNumbers(query) {
          this.search_cases = query;
          this.searchCases();
        },

        searchCases() {

          this.searching = true;

          this.$http.get('/cases?slim=true&limit=1000&fields=case_number&search='+this.search_cases)
          .then(response => {
            if(response && response.data) {

              this.searching = false;

              if(!response.data.length) {
                return;
              }

              this.cases_results = response.data.map(result => {
                return result.case_number;
              });

            }
          })
        },

        getEventCase() {

            if(!this.form || !this.form.case_number) {
                this.searchCases();
                return;
            }

            this.$http.get('/cases?limit=1&slim=true&fields=uuid&case_number='+this.form.case_number)
            .then(response => {
                if(response && response.data && response.data[0]) {
                    this.$http.get('/cases/'+response.data[0].uuid)
                    .then(response => {
                      if(response && response.data) {
                        this.event_case = response.data;
                      }
                    })
                }
            })
        },

        getStatus(uuid) {
            this.$http.get('/'+this.$route.meta.base_url+'/'+uuid+'/status')
            .then(response => {
                if(response && response.data) {
                    this.event_status = response.data;
                }
            })
        },

        getCalendars() {

            if(!this.form.uuid) {
                return;
            }

            if(this.calendar_links && this.calendar_links.length > 0) {
              return;
            }

            this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/calendars')
            .then(response => {
                if(response && response.data) {
                    this.calendar_links = response.data;
                }
            })
        },

        restoreRecord () {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url+'/' + this.form.uuid + '/restore')
            .then(response => {
                if (response && response.data) {
                  new this.$noty({text: response.data.message || 'Restored'}).show();
                  this.form.deleted_at = null;
                  this.form.archived_at = null;
                  this.processing = false;
                }
            })
        },

        archiveRecord () {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url+'/' + this.form.uuid + '/archive')
            .then(response => {
                if (response && response.status) {
                    new this.$noty({text: response.data.message || 'Archived'}).show();
                    this.form.archived_at = new Date().toISOString();
                    this.processing = false;
                }
            })
        },

        trashRecord () {
            this.processing = true;
            this.$http.delete('/'+this.$route.meta.base_url+'/' + this.form.uuid)
            .then(response => {
                if(response && response.status === 204) {
                    new this.$noty({text: this.title + ' trashed', type: 'error'}).show();
                    this.form.deleted_at = new Date().toISOString();
                    this.processing = false;
                }
            })
        },

        syncRecord () {
            new this.$noty({text: 'Syncing messages'}).show();
            this.$eventBus.$emit('eventSynced', this.form.uuid);
            this.$http.post('/'+this.$route.meta.base_url+'/' + this.form.uuid + '/process')
            .then(response => {
                if(response && response.status === 202) {
                  new this.$noty({text: 'Messages synced'}).show();
                }
            })
        },

      duplicateRecord() {

        this.processing = true;

        var form = {};

        form = this.form;

        var fields = ['uuid', 'client', 'upload', 'url', 'flow_name', 'location_name', 'date_time', 'is_now', 'is_past', 'first_name', 'last_name', 'client_uuid', 'language', 'timezone', 'reached', 'appeared', 'created_by', 'created_at', 'updated_at', 'archived_at', 'deleted_at'];

        fields.forEach(f => {
            delete form[f];
        });

        this.$http.post('/'+this.$route.meta.base_url, form)
            .then(response => {
              if(response && response.status === 201) {
                this.processing = false;
                new this.$noty({text: this.title + ' duplicated'}).show();
                this.$router.push('/'+this.$route.meta.base_url+'/'+response.data.uuid);
              }
            })
      },

        saveICS() {
            this.processing = true;
            this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/ics')
            .then(response => {
                if(response && response.data) {

                    window.open( "data:text/calendar;charset=utf8," + escape(response.data), '_blank');

                    this.processing = false;
                }
            })
        },

    getUnreachables() {
      this.$axios.get(this.$root.assets_url+'js/unreachables.json')
          .then(response => {
            if(response && response.data) {
              this.unreachables = response.data;
            }
          })
    },

    getFlows () {
        this.$http.get(this.buildQueries('/flows', this.default_queries))
        .then(response => {
            if (response && response.data) {
                this.flows = response.data;
            }
        })
    },

    getPortals () {
        this.$http.get(this.buildQueries('/portals', this.default_queries))
        .then(response => {
            if (response && response.data) {
                this.portals = response.data;
            }
        })
    },

    getLocations () {
        this.$http.get(this.buildQueries('/locations', this.default_queries))
        .then(response => {
            if (response && response.data) {
                this.locations = response.data;
            }
        })
    },


    getRooms() {
      this.$http.get('/rooms?limit=1000&slim=true&fields=name,uuid,location&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.rooms = response.data;
        }
      })
    },

    setLocationRoom() {
      if(!this.form.room) {
        this.form.location_notes = null;
        return;
      }

      var notes = this.rooms.find(room => room.uuid == this.form.room).name || null;

      if(!notes) {
        return;
      }

      notes = notes.replace(/^Room: /, '');

      this.form.location_notes = 'Room: '+notes;
    },

    resetForm() {
        this.form = {
            client: null,
            date: null,
            end_date: null,
            time: null,
            end_time: null,
            event_reference: null,
            case_number: null,
            flow: null,
            portal: null,
            location: null,
            location_notes: null,
            virtual_notes: null,
            description: null,
            type: null,
            status: null,
            appeared: null,
            virtual: false,
            judge_name: null,
            internal_notes: null,
            url: null
        }
    },

    setNewJudge() {
      if(this.form.judge_name == 'new_judge') {
        this.form.judge_name = this.new_judge;
        this.new_judge = null;

        if(!this.judges) {
          this.judges = [];
        }

        this.judges.push({name: this.form.judge_name});
      }
    },

    postForm() {

        this.processing = true;

        this.setNewJudge();

        this.$eventBus.$emit('eventSynced', this.form.uuid);


        var fields = ['location_data', 'location_name'];

        fields.forEach(f => {
          if(this.form[f]) {
            delete this.form[f];
          }
        });

        if(!this.form.location) {
          this.form.location = null;
        }

        this.form.sync_now = false;

        this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
        .then(response => {
            if(response && response.data) {
  
                this.processing = false;

                if(response.data.updated_at) {
                    new this.$noty({text: this.title + ' updated'}).show();
                    this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/process')
                    this.getRecord();
                }          
            }
        })
    },

    getStatuses() {
        this.$http.get('/statuses?slim=true&limit=1000&sort=name&sortDirection=asc&type=event&fields=name,fta')
        .then(response => {
            if(response && response.data) {
                this.statuses = response.data;
              
                this.statuses = this.statuses.filter(status => {
                    return status.fta != 1;
                });

                this.appeareds = response.data.filter(status => {
                    return status.fta == 1;
                });

                if(!this.appeareds || this.appeareds.length == 0) {
                    this.appeareds = [
                        {name: 'APPEARED'},
                        {name: 'FTA'},
                        {name: 'checked-in'}
                    ]
                }
            }
        })
    },

    getEventTypes() {
        this.$http.get('/event_types')
        .then(response => {
            if(response && response.data) {
                this.event_types = response.data;
            }
        })
    },

    showNewType() {
      if(this.show_new_type === true) {
        this.show_new_type = false;
        return;
      }

      this.show_new_type = true;
    },

    getJudges() {
        this.$http.get('/judges?limit=20&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
        .then(response => {
            if(response && response.status === 200) {
                this.judges = response.data || [];

                this.pushJudge();
            }
        })
    },

    pushJudge() {

    if(!this.form.judge_name) {
      return;
    }

    if(!this.judges) {
      this.judges = [];
    }

    if(this.judges.filter(judge => judge.name == this.form.judge_name).length > 0) {
      return;
    }

    this.judges.push({name: this.form.judge_name});
    },

    getClient(uuid) {
      this.processing = true;
      this.$http.get('/clients/'+uuid)
      .then(response => {
        if(response && response.status === 200) {

          if(response.data && response.data.uuid) {
            this.form.client = response.data;
          }

          this.processing = false;
        }
      })
    },

    getAttached() {
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/attached')
      .then(response => {
        if(response && response.data) {
          this.clients = response.data;
        }
      })
    },

    updateAppeared(value) {

      if(!this.form.uuid) {
        return;
      }

      this.form.appeared = value;
      this.postForm();
    },

    setDateDiff(form) {
        if(!form.date || !form.time) {
          return;
        }

        return this.$options.filters.timeago(form.date+' '+form.time);
      },


      searchAttachableClients() {
      this.processing = true;
      this.$http.get('/search?search='+this.search)
      .then(response => {
          if(response && response.data) {
              this.processing = false;
              this.records = response.data;
          }
      })
  },

  removePrimary(client_uuid) {
    this.form.client = null;
    this.form.client_uuid = null;


    if(!this.clients) {
      this.clients = [];
    }

    //remove from attached
    this.clients = this.clients.filter(client => client.client != client_uuid);

    this.postForm();
  },

  makePrimary(client_uuid) {
    this.processing = true;

    this.form.client = client_uuid;

    this.postForm();
  },

  detachRecord(client_uuid) {
    this.processing = true;
    this.$http.delete('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/detach?client='+client_uuid)
    .then(response => {
      if(response && response.status === 200) {
        this.getAttached();
        this.processing = false;
      }
    })
  },


  attachRecord(record) {

    this.processing = true;
    
    this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/attach?client='+record.uuid)
    .then(response => {
      if(response && response.status === 201) {

        this.processing = false;

        this.records = [];
        this.search = '';

        // this.$eventBus.$emit('attach_client', record.uuid);

        this.getAttached();

        new this.$noty({text: 'Client attached'}).show();
      }
    })  
  },

  isAttached(client_uuid) {

    if(this.form.client_uuid == client_uuid) {
      return true;
    }

    return this.clients.filter(client => client.client == client_uuid).length > 0;
  }

}
}
</script>