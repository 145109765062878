<template>
  <div>

  </div>
</template>
<script>
export default {

props: ['event', 'clients'],

data() {
    return {
        processing: false,
        base_url: '/events',
        search: '',
        records: []
    }
},

methods: {


  getSearch() {
      this.processing = true;
      this.$http.get('/search?search='+this.search)
      .then(response => {
          if(response && response.data) {
              this.processing = false;
              this.records = response.data;
          }
      })
  },

  attachRecord(record) {

    this.processing = true;
    
    this.$http.post(this.base_url+'/'+this.event.uuid+'/attach?client='+record.uuid)
    .then(response => {
      if(response && response.status === 201) {

        this.processing = false;

        this.records = [];
        this.search = '';

        this.$eventBus.$emit('attach_client', record.uuid);

        new this.$noty({text: 'Client attached'}).show();
      }
    })

  }

}

}
</script>