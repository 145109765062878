<template>
  <div>

      <page-tabs page="setup" />

      <div class="row">
        <div class="col-md-6 mx-auto">

          <h1>{{ $route.meta.title }}</h1>

          <p class="lead">An agency is a workspace for managing clients, messages, and related data. Authorized users can access the agency to view and manage data with assigned roles and permissions.</p>

          <p>Create separate agencies for different departments, locations, or teams. Each agency can have its own settings, users, and data sources.</p>

          <div class="card">
            <div class="card-body">
              
              <h3>Welcome to eCourtDate!</h3>

              <p class="lead">We're excited to have you join. Let's get started with your agency onboarding.</p>

              <template v-if="!form || !form.uuid && !processing">
                <button @click="createForm" class="btn btn-success" :disabled="processing">Start Onboarding</button>
              </template>

              <template v-if="form && form.uuid">

                <h4 class="my-3">Onboarding Details</h4>

                <template v-if="form && form.status && form.status != 'active'">
                  <div class="alert alert-warning">Your onboarding is currently {{ form.status }}. You can activate it below.</div>
                </template>

                <form @submit.prevent="postForm">

                  <div class="form-group">
                    <label for="go_live">Target Go Live Date</label>
                    <input type="date" class="form-control" id="go_live" v-model="form.go_live" required>
                    <p class="form-text text-muted">When do you want to be live with eCourtDate for this agency?</p>
                  </div>

                  <div class="form-group">
                    <label for="referral_source">How did you hear about eCourtDate?</label>
                    <select class="form-control" id="referral_source" v-model="form.referral_source" required>
                      <option value="">Select</option>
                      <option v-for="source in referral_sources" :value="source">{{ source }}</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="cms">Which Case Management System do you use?</label>
                    <select class="form-control" id="cms" v-model="form.cms">
                      <option value="">Select</option>
                      <option v-for="cms in cms_systems" :value="cms.key">{{ cms.name }}

                        <template v-if="cms.vendor != cms.name">
                          ({{ cms.vendor }})
                        </template>
                      </option>
                    </select>
                  </div>

                  <template v-if="form.cms">
                    <div class="form-group">
                      <label for="cms_version">CMS Version</label>
                      <input type="text" class="form-control" id="cms_version" v-model="form.data['cms_version']">
                    </div>
                  </template>

                  <template v-if="form.cms">
                    <div class="form-group">
                      <label for="cms_hosting">CMS Hosting</label>
                      <select class="form-control" id="cms_hosting" v-model="form.data['cms_hosting']">
                        <option value="">Select</option>
                        <option value="self-hosted">Self Hosted on Premises</option>
                        <option value="cloud-hosted">Self Hosted on Cloud</option>
                        <option value="managed-hosted">Managed Hosting by Vendor</option>
                        <option value="saas-hosted">SaaS Hosted by Vendor</option>
                      </select>
                    </div>
                  </template>

                  <div class="form-group">
                    <label for="phase">Current Phase</label>
                    <select class="form-control" id="phase" v-model="form.data['phase']">
                      <option value="">Select</option>
                      <option value="Planning">Planning</option>
                      <option value="Implementation">Implementation</option>
                      <option value="Testing">Testing</option>
                      <option value="Training">Training</option>
                      <option value="Go Live">Go Live</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="notes">Onboarding Notes</label>
                    <textarea class="form-control" id="notes" v-model="form.notes" rows="5"></textarea>
                  </div>

                  <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

                  </form>

                <h3 class="my-3">Onboarding Tasks</h3>

                <ul class="list-group">

                  <template v-for="task in tasks">
                    <li class="list-group-item">
                      <a :href="task.link" target="_blank">{{ task.name }}</a>
                      <p class="m-0">{{ task.description }}</p>

                      <template v-if="form.data && form.data.tasks && form.data.tasks.includes(task.name)">
                        <span class="badge badge-pill badge-success">Complete</span>
                        <button @click="completeTask(task.name)" class="btn btn-outline-danger btn-sm float-right" :disabled="processing"><i class="fa fa-thumbs-down"></i> Mark as Incomplete</button>
                      </template>

                      <template v-else>
                        <span class="badge badge-pill badge-secondary">Incomplete</span>
                        <button @click="completeTask(task.name)" class="btn btn-outline-success btn-sm float-right" :disabled="processing"><i class="fa fa-thumbs-up"></i> Mark as Complete</button>
                      </template>
                    </li>
                  </template>

                </ul>

                <div hidden>

                <div class="form-group mt-3">
                  <input type="text" class="form-control" v-model="new_task" placeholder="Enter a new task">
                </div>

                <button type="button" @click="createTask" class="btn btn-success btn-sm" :disabled="processing">Create Task</button>

                </div>

                <h3 class="my-3">Onboarding Status</h3>

                <template v-if="form.status == 'complete'">
                  <p class="badge badge-pill badge-success text-uppercase">Complete</p>
                </template>

                <template v-if="form.status == 'active'">
                  <p class="badge badge-pill badge-primary text-uppercase">Active</p>
                </template>

                <template v-if="form.status == 'paused'">
                  <p class="badge badge-pill badge-warning text-uppercase">Paused</p>
                </template>

                <template v-if="form.status == 'canceled'">
                  <p class="badge badge-pill badge-danger text-uppercase">Canceled</p>
                </template>

                <br>

                <div class="btn-group mb-3">

                <template v-if="form.status != 'active'">
                  <button @click="activateOnboarding" class="btn btn-success" :disabled="processing">Activate Onboarding</button>
                </template>

                <template v-if="form.status != 'canceled' && form.status != 'complete' && form.status != 'paused'">
                  <button @click="cancelOnboarding" class="btn btn-danger" :disabled="processing">Cancel Onboarding</button>
                </template>

                <template v-if="form.status != 'paused' && form.status != 'complete' && form.status != 'canceled'">
                  <button @click="pauseOnboarding" class="btn btn-warning" :disabled="processing">Pause Onboarding</button>
                </template>

                <template v-if="form.status == 'active'">
                  <button @click="completeOnboarding" class="btn btn-success" :disabled="processing">Complete Onboarding</button>
                </template>

                </div>

                <h3 class="mt-3 mb-0">Who do you want to notify?</h3>

                <p class="lead m-0">Select the clients that apply to your agency. Clients are any recipients of messages from your agency.</p>

                <div class="form-check" v-for="client_type in client_types">
                  <input type="checkbox" 
                    class="form-check-input" 
                    :id="'client_type_' + client_type"
                    :value="client_type" 
                    @change="toggleClientType(client_type)"
                    :checked="isEnabledClientType(client_type)"
                    :disabled="processing">
                  <label :for="'client_type_' + client_type" class="form-check-label">{{ client_type }}</label>
                </div>


                  <h3 class="my-3">Use Cases</h3>

                  <p class="lead">Select the use cases that apply to your agency.</p>

                  <template v-if="hideEnableAllUseCases()">
                    <button type="button" class="btn btn-danger btn-sm" @click="disableAllUseCases()" :disabled="processing">Disable All</button>
                  </template>
                  <template v-else>
                    <button type="button" class="btn btn-success btn-sm" @click="enableAllUseCases()" :disabled="processing">Enable All</button>
                  </template>
                  
                  <div class="row">

                    <template v-for="use_case in use_cases">
                      <div class="col-sm-12 col-md-6 my-3">

                        <div class="card">
                          <div class="card-body">

                          <template v-if="isEnabledUseCase(use_case)">
                            <button type="button" class="btn btn-outline-success btn-sm float-right" @click="enableUseCase(use_case)" :disabled="processing"><i class="fa fa-check-circle"></i></button>
                          </template>
                          <template v-else>
                            <button type="button" class="btn btn-outline-danger btn-sm float-right" @click="enableUseCase(use_case)" :disabled="processing"><i class="fa fa-times-circle"></i></button>
                          </template>

                            <template v-if="use_case.title">
                              <h4 class="text-capitalize">{{ use_case.title | rmDashes }}</h4>                            
                            </template>

                            <template v-if="use_case.description">
                              <p>{{ use_case.description }}</p>
                            </template>
                              

                          </div><!--Body-->
                        </div><!--Card-->

                      </div><!--Col-->
                    </template>

                    </div><!--Row-->


            <h3 class="mt-3">Data Sources</h3>

            <p class="lead">Select the data sources that apply to your agency.</p>

            <template v-if="hideEnableAllDataSources()">
              <button type="button" class="btn btn-danger btn-sm" @click="disableAllDataSources()" :disabled="processing">Disable All</button>
            </template>
            <template v-else>
              <button type="button" class="btn btn-success btn-sm" @click="enableAllDataSources()" :disabled="processing">Enable All</button>
            </template>

                  <div class="row">

                    <template v-for="data_source in data_sources">
                      <div class="col-sm-12 col-md-6 my-3">

                        <div class="card">
                          <div class="card-body">

                          <template v-if="isEnabledDataSource(data_source)">
                            <button type="button" class="btn btn-outline-success btn-sm float-right" @click="enableDataSource(data_source)" :disabled="processing"><i class="fa fa-check-circle"></i></button>
                          </template>
                          <template v-else>
                            <button type="button" class="btn btn-outline-danger btn-sm float-right" @click="enableDataSource(data_source)" :disabled="processing"><i class="fa fa-times-circle"></i></button>
                          </template>

                            <h4 class="text-capitalize">{{ data_source['title'] | rmDashes }}</h4>

                            <p>{{ data_source['description'] }}</p>

                            <template v-if="isEnabledDataSource(data_source)">
                              <p class="small">Recommended steps to setup this data source:</p>
                              <ul>
                                <template v-for="suggestion in data_source['suggestions']">
                                  <li class="small" v-html="suggestion"></li>
                                </template>
                              </ul>
                            </template>

                          </div><!--Body-->
                        </div><!--Card-->

                      </div><!--Col-->
                    </template>

                  </div><!--Row-->

              </template>


              <template v-if="superAdmin">
                <h4 class="my-3"><i class="fa fa-wand-magic-sparkles"></i> Auto Setup Agency</h4>

              <p class="lead">Automatically configure this agency.</p>

              <form @submit.prevent="postSetup">

              <div class="form-group">
                <label for="name">Agency Name</label>
                <input type="text" class="form-control" id="name" v-model="setup.name" required>
              </div>

              <div class="form-group">
                <label for="phone">Agency Phone</label>
                <input type="tel" class="form-control" id="phone" v-model="setup.phone" required>
              </div>

              <div class="form-group">  
                <label for="email">Agency Email</label>
                <input type="email" class="form-control" id="email" v-model="setup.email" required>
              </div>

              <div class="form-group">
                <label for="website">Agency Website</label>
                <input type="url" class="form-control" id="website" v-model="setup.website" required>
                <small class="form-text text-muted">https://agency.gov</small>
              </div>

              <div class="form-group">
                <label for="full_address">Agency Address</label>
                <input id="full_address" class="form-control" v-model="setup.full_address" autocomplete="street-address">
                {{ setup.address }} {{ setup.city }} {{ setup.state }} {{ setup.zip }}
              </div>

              <div class="form-group">
                <label for="timezone">Timezone</label>
                <select id="timezone" class="form-control" v-model="setup.timezone" :disabled="processing">
                  <option value=""></option>
                  <option v-for="timezone in timezones" :value="timezone.key">{{ timezone.label }}</option>
                </select>
              </div>

                <button type="submit" class="btn btn-success btn-block" :disabled="processing" :hidden="auto_setup_status != ''">
                  <i class="fa fa-wand-magic-sparkles"></i> Start Auto Setup
                </button>

              </form>

                <template v-if="auto_setup_status">
                  <div class="alert alert-success">{{ auto_setup_status }}</div>
                </template>

                <p class="text-muted">We only recommend auto setup for new agencies or agencies that have not yet configured their settings.</p>

                <template v-if="auto_setup_status && auto_setup_status != 'Auto Setup Complete!'">
                  <p class="text-muted">Please wait while we configure your agency. This may take a few minutes.</p>
                </template>

                <template v-if="auto_setup_status && auto_setup_status == 'Auto Setup Complete!'">
                  <p class="text-muted">Your agency has been auto configured. We recommend testing in Simulate Mode before going live.</p>
                  <a href="/checklist" class="btn btn-primary btn-sm">Go to Checklist</a>
                </template>

              </template>


            </div><!--Body-->
          </div><!--Card-->
        </div><!--Col-->
      </div><!--Row-->

  </div>
</template>

<script>
import { autofill } from '@mapbox/search-js-web';
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

data() {
    return {
        processing: false,
        current_case: null,
        use_cases: [],
        current_source: null,
        form: {},
        cms_systems: [],
        new_task: '',
        setup: {
          website: '',
          phone: '',
          address: '',
          address_2: '',
          city: '',
          state: '',
          zip: '',
          area_code: '',
        },
        tasks: [
          {
            name: 'Invite authorized users',
            description: 'Invite users to your agency to manage clients and events.',
            link: '/users'
          },
          {
            name: 'Review Locations',
            description: 'Go to Admin > Locations and review any Locations that apply to your agency.',
            link: '/locations'
          },
          {
            name: 'Schedule Onboarding Meeting',
            description: 'Schedule a meeting with our team to discuss your agency\'s needs and goals.',
            link: 'https://portals.ecourtdate.com/schedule?template=6abdb0bb-03fc-450e-a7b0-fac03b2efc3a'
          },
          {
            name: 'Billing Info',
            description: 'Update your billing information to ensure uninterrupted service.',
            link: '/billing'
          },
          {
            name: 'Carrier Registration',
            description: 'Register your carrier to send and receive messages.',
            link: '/carriers'
          },
          {
            name: 'Complete Agency Checklist',
            description: 'Complete the agency checklist to ensure all settings are configured properly.',
            link: '/checklist'
          }
        ],
        referral_sources: ['Search Engine', 'Social Media', 'Referral', 'Conference', 'RFP', 'Partner', 'Other'],
        data_sources: [
        {
            title: 'Manual Entry',
            description: 'Add clients and related data manually one at a time through this application.',
            suggestions: [
                'Ensure the agency checklist is complete',
                'Review any CLIENT level settings in Admin > Settings tab',
                'Consider whether users who create clients should be auto-assigned to the client',
                'Create limited Roles for users who will only manage clients',
                'Ensure any DEFAULT/PRIMARY settings are configured to minimize manual data entry by users',
                'Create Flows/Auto Messages to minimize the need to create one-off messages by users',
                'Create Bulk Action Templates to streamline sending a bulk one-off message to clients',
                'Review Auto-Archive, Auto-Trash, and Duplicate Checks settings to minimize manual and duplicate data entry',
                'Schedule a training session for users who will manage clients',
                'Invite users to a staging agency to practice managing clients (or ensure that your production agency is in Simulate Mode)',
                'Start adding clients and related data manually one at a time through the CLIENTS tab',
                'Provide a dedicated phone number and email address per user to communicate with clients',
                'Consider using the Portal to allow clients to self-enroll and manage their own contact preferences',
            ],
          },
          {
            title: 'Client Self Enroll',
            description: 'Allow clients to self-enroll through QR codes, web forms, and inbound messages.',
            suggestions: [
                'Ensure the agency checklist is complete',
                'In Admin > Portals, setup at least one Portal to allow clients to self-enroll',
                'Enable Opt In keywords to allow clients to self-enroll through inbound messages',
                'Configure Auto Messages to send a welcome message to clients who self-enroll',
                'Enable Client Authentication in the Portal to allow clients to manage their own contact preferences',
                'Review default client GROUPS and STATUS settings to ensure that clients who self-enroll are managed properly',
                'Create QR codes to allow clients to self-enroll with pre-filled data'
            ]
          },
          {
            title: 'Manual Bulk Uploads',
            description: 'Add clients and related data by manually uploading flat files.',
            suggestions: [
                'Ensure the agency checklist is complete',
                'Decide on which data fields to include in your flat file. Check Uploads > File Templates for sample file templates',
                'Get a sample file from your data source (e.g. Case Management System) to use as a starting point',
                'Ensure that you are on staging or in Simulate Mode to avoid sending messages to real clients',
                'Create an Upload Template to map your flat file to the eCourtDate fields and to set processing options',
                'Start uploading flat files through the UPLOADS tab',
                'Review the results of the upload in the UPLOADS tab',
                'Configure Flows/Auto Messages to trigger messages automatically based on the data you uploaded',
                'Run a Bulk Action to send a one-off message to the clients you uploaded',
          ]
          },
          {
            title: 'Automated Uploads',
            description: 'Upload flat files automatically through our SFTP Gateway.',
            suggestions: [
                'Ensure the agency checklist is complete',
                'Follow the steps in the Manual Bulk Uploads data source to prepare your flat file',
                'Contact our team to setup an SFTP Gateway server. If you already have one, go to <a href="https://console.ecourtdate.com/sftps" target="_blank">Console > SFTPs</a> to configure your server',
                'Create an Upload Template to map your flat file to the eCourtDate fields and to set processing options',
                'Start uploading flat files through the SFTP Gateway',
                '<a href="https://sftp.ecourtdate.com" target="_blank">Read our SFTP Gateway Guide here</a>',
            ]
          },
          {
            title: 'API Integration',
            description: 'Integrate with our API to create and update data and send and receive messages.',
            suggestions: [
                'Ensure the agency checklist is complete',
                'Review the <a href="https://devs.ecourtdate.com" target="_blank">Developer Guide</a> to get started with our API',
                'Manage your API keys in the <a href="https://console.ecourtdate.com/apis" target="_blank">Console > APIs</a>',
                'Use the API with a tool like Postman or Insomnia to test your requests',
                '<a href="https://webhooks.ecourtdate.com" target="_blank">Create Webhooks</a> to receive messages, check-ins, and status changes from eCourtDate',
            ]
          },
          {
            title: 'Partner Integrations',
            description: 'Use one of our partner integrations to integrate eCourtDate with your system.',
            suggestions: [
                'Ensure the agency checklist is complete',
                'Review the <a href="https://ecourtdate.com/partners" target="_blank">Justice System Partners</a> and <a href="https://ecourtdate.com/integrations" target="_blank">One-Click Integrations</a> pages on our website to see if we have a partner integration that fits your system',
                'Manage your partner integrations in the <a href="https://console.ecourtdate.com/integrations" target="_blank">Console > Integrations</a> page',
                'Send an email to help@ecourtdate.com to request a new integration',
            ]
          }
        ],
        use_case_steps: {
          'court-reminders': 'Setup Flow and Auto Messages; then configure data sources to automatically create clients and events. If manually, review general settings to streamline data entry. Allow clients to self-enroll and manage their own contacts with a Portal',
          'jury-management': 'Ensure a Portal is created with Check-In enabled. Enable check-ins for any Events in the Admin > Settings page. Send bulk messages and randomly select jurors through the Bulk Actions page',
          'calendars-scheduling': 'Ensure a Portal is created, then manage Calendars and Scheduling in the Events page',
          'forms-files-library': 'Ensure a Portal is created, then manage Forms and Files in Admin > Portals',
          'pretrial-and-probation-supervision': 'Ensure a Portal is created with Geolocation required, then create Supervision Plans in the Admin > Settings page',
          'evidence-management': 'Ensure a Portal is created and create Forms that allow for case-based file uploads',
          'data-dashboards': 'Ensure a Dashboard is created and manage settings in the Admin > Dashboards page',
          'visitor-sign-in': 'Ensure any Locations are created and a Portal is created with Check-In enabled. Enable check-ins for any Events in the Admin > Settings page',
          'victim-notifications': 'Setup Flow and Auto Messages; then configure data sources to automatically create clients and events. If manually, review general settings to streamline data entry. Allow clients to self-enroll and manage their own contacts with a Portal',
        },
        auto_setup_status: '',
        client_types: ['Defendant', 'Juror', 'Victim', 'Attorney', 'Witness', 'General Public', 'Parent', 'Staff/Employee', 'External Agency', 'Company', 'School', 'Hospital', 'Other'],
        autofill: {}
    }
},

computed: {

superAdmin () {
    return this?.$root?.current_user?.super_admin;
}

},

mounted() {
  this.initAutofill();
},

created() {
    document.title = this.$route.meta.title + ' | eCourtDate.com';
    this.resetForm();
    this.getUseCases();
    this.getCaseSystems();
    this.getOnboarding();
    // this.getSetup();
    this.getStates();
    this.getTimezones();

    //delay to allow timezones to load
    setTimeout(() => {
      this.setTimezone();
    }, 3000);
},

methods: {

  resetForm() {
    this.form = {
      go_live: null,
      notes: null,
      referral_source: null,
      cms: null,
      data: {
        use_cases: [],
        custom_use_case: '',
        data_sources: [],
        custom_data_source: '',
        cms_version: '',
        cms_hosting: '',
        tasks: [],
        phase: 'Qualifying'
      },
      phases: ['Qualifying', 'Planning', 'Implementation', 'Testing', 'Training', 'Go Live']
    }
  },

    getOnboarding() {
      this.processing = true;
      this.$http.get('/agencies/onboarding')
      .then(response => {
        if(response && response.status) {
          if(response.data.uuid) {
            this.form = response.data;
          }

          this.processing = false;
        }
      })
    },

    getUseCases() {
      this.$axios.get('https://assets.ecourtdate.com/js/use-cases.json')
      .then(response => {
        if(response && response.data) {
          this.use_cases = response.data;
        }
      })
    },

    getCaseSystems() {
      this.$axios.get('https://assets.ecourtdate.com/js/cms-systems.json')
      .then(response => {
        if(response && response.data) {
          this.cms_systems = response.data;

          this.cms_systems.push({
            name: 'In-House',
            vendor: 'In-House',
            key: 'in-house'
          });

          this.cms_systems.push({
            name: 'Other',
            vendor: 'Other',
            key: 'other'
          });

          this.cms_systems.push({
            name: 'None',
            vendor: 'None',
            key: 'none'
          });

        }
      })
    },

    updateUseCase(use_case) {
        
        if(this.current_case && this.current_case.title == use_case.title) {
          this.current_case = null;
          return;
        }
  
        this.current_case = use_case;
    },

    isEnabledUseCase(use_case) {

      if(!this.form.data || !this.form.data.use_cases) {
        return false;
      }

      if(!use_case.title) {
        return false;
      }

      return this.form.data.use_cases && this.form.data.use_cases.includes(use_case.title);
    },

    enableUseCase(use_case) {

      if(!this.form.data.use_cases) {
        this.form.data.use_cases = [];
      }

      if(this.form.data.use_cases.includes(use_case.title)) {
        this.form.data.use_cases = this.form.data.use_cases.filter(item => item !== use_case.title);
      } else {
        this.form.data.use_cases.push(use_case.title);
      }

      this.postForm();
    },

    enableDataSource(data_source) {

      if(!this.form.data.data_sources) {
        this.form.data.data_sources = [];
      }

      if(this.form.data.data_sources.includes(data_source.title)) {
        this.form.data.data_sources = this.form.data.data_sources.filter(item => item !== data_source.title);
      } else {
        this.form.data.data_sources.push(data_source.title);
      }

      this.postForm();
    },

    isEnabledDataSource(data_source) {

      if(!this.form.data || !this.form.data.data_sources) {
        return false;
      }

      if(!data_source.title) {
        return false;
      }

      return this.form.data.data_sources && this.form.data.data_sources.includes(data_source.title);
    },

    hideEnableAllUseCases() {

      if(!this.form.data || !this.form.data.use_cases) {
        return false;
      }

      return this.use_cases.length == 0 || this.use_cases.length == this.form.data.use_cases.length;
    },

    hideEnableAllDataSources() {

      if(!this.form.data || !this.form.data.data_sources) {
        return false;
      }

      return this.data_sources.length == 0 || this.data_sources.length == this.form.data.data_sources.length;
    },

    enableAllUseCases() {
      this.form.data.use_cases = this.use_cases.map(use_case => use_case.title);
      this.postForm();
    },

    enableAllDataSources() {
      this.form.data.data_sources = this.data_sources.map(data_source => data_source.title);
      this.postForm();
    },

    disableAllUseCases() {
      this.form.data.use_cases = [];
      this.postForm();
    },

    disableAllDataSources() {
      this.form.data.data_sources = [];
      this.postForm();
    },

    createTask() {

      if(!this.new_task) {
        new this.$noty({text: 'Please enter a task.', type: 'error'}).show();
        return;
      }


      if(!this.form.data || !this.form.data.tasks) {
        this.form.data.tasks = [];
      }

      this.form.data.tasks.push(this.new_task);

      this.new_task = '';

      this.postForm();
    },

    completeTask(task) {

      if(!this.form.data || !this.form.data.tasks) {
        this.form.data.tasks = [];
      }

      if(this.form.data.tasks.includes(task)) {
        this.form.data.tasks = this.form.data.tasks.filter(item => item !== task);
      } else {
        this.form.data.tasks.push(task);
      }

      this.postForm();
    },

    postForm() {
      this.processing = true;

      if(this.form.data && this.form.data.use_cases) {
        this.form.use_cases = this.form.data.use_cases;
      }

      if(this.form.data && this.form.data.data_sources) {
        this.form.data_sources = this.form.data.data_sources;
      }

      if(this.form.data && this.form.data.tasks) {
        this.form.tasks = this.form.data.tasks;
      }

      if(!this.form.status) {
        this.form.status = 'active';
      }

      this.$http.post('/agencies/onboarding', this.form)
      .then(response => {
        if(response && response.data) {
          this.form = response.data;
          this.processing = false;
          new this.$noty({text: 'Saved!', type: 'success'}).show();
        }
      })
    },

    createForm() {
      this.processing = true;

      this.form = {
        go_live: this.$moment().add(30, 'days').format('YYYY-MM-DD'),
        notes: null,
        referral_source: null,
        status: 'active'
      }

      this.$http.post('/agencies/onboarding', this.form)
      .then(response => {
        if(response && response.data) {
          this.form = response.data;
          this.processing = false;
          new this.$noty({text: 'Saved!', type: 'success'}).show();
        }
      })
    },

    cancelOnboarding() {
      this.form.status = 'canceled';
      this.postForm();
    },

    pauseOnboarding() {
      this.form.status = 'paused';
      this.postForm();
    },

    activateOnboarding() {
      this.form.status = 'active';
      this.postForm();
    },

    completeOnboarding() {
      this.form.status = 'complete';
      this.postForm();
    },

    postSetup() {

      this.auto_setup_status = '';

      if(!this.form.data || !this.form.data.use_cases || this.form.data.use_cases.length == 0) {
        new this.$noty({text: 'Please select at least one use case', type: 'error'}).show();
        return;
      }

      if(!this.form.data || !this.form.data.data_sources || this.form.data.data_sources.length == 0) {
        new this.$noty({text: 'Please select at least one data source', type: 'error'}).show();
        return;
      }

      this.setup.use_cases = this.form.data.use_cases;
      this.setup.data_sources = this.form.data.data_sources;

      this.processing = true;

      this.$http.post('/agencies/setup', this.setup)
      .then(response => {
        if(response && response.data) {
          this.processing = false;
          this.auto_setup_status = 'Your agency is being automatically setup. This may take a few minutes. You will be notified when complete.';
        }
      })

    },

    getSetup() {
      if(!this.settings || !this.settings.name) {
        return;
      }

      let fields = ['name', 'website', 'phone', 'address', 'address_2', 'city', 'state', 'zip', 'area_code'];

      fields.forEach(field => {
        if(this.settings[field]) {
          this.setup[field] = this.settings[field];
        }
      })
    },

    isEnabledClientType(client_type) {

      if(!this.form.data || !this.form.data.client_types) {
        return false;
      }

      if(!Array.isArray(this.form.data.client_types)) {
        return false;
      }

      return this.form.data.client_types.includes(client_type);
    },

    toggleClientType(client_type) {

      if(!this.form.data || !this.form.data.client_types || this.form.data.client_types.length == 0) {
        this.form.data.client_types = [];
      }

      //if not array, make it an array
      if(!Array.isArray(this.form.data.client_types)) {
        this.form.data.client_types = [];
      }


      if(this.form.data.client_types.includes(client_type)) {
        this.form.data.client_types = this.form.data.client_types.filter(item => item !== client_type);
      } else {
        this.form.data.client_types.push(client_type);
      }

      this.postForm();
    },

    initAutofill() {

var options = { language: 'en', country: 'us' };

if(this.settings && this.settings['country']) {
  options['country'] = this.settings['country'];
}

if(this.settings && this.settings['latitude'] && this.settings['longitude']) {
  options['proximity'] = [this.settings['longitude'], this.settings['latitude']];
}

this.autofill = new autofill({
  accessToken: this.$root.mapbox_token,
  options: options
});

var self = this;

this.autofill.addEventListener('retrieve', (event) => {

  if (!event.detail || !event.detail.features || !event.detail.features.length) {
    console.log('no features');
    return;            
  }

  const features = event.detail.features[0];

  if(features['geometry'] && features['geometry']['coordinates']) {
    self.setup.lat = features['geometry']['coordinates'][1] || null;
    self.setup.lng = features['geometry']['coordinates'][0] || null;
  }

  if(!features['properties']) {
    console.log('no properties');
    return;
  }

  console.log(features['properties']);

  if(features['properties']['address_line1']) {
    self.setup.address = features['properties']['address_line1'];
  }

  if(features['properties']['district']) {
    self.setup.county = features['properties']['district'];
  }

  if(features['properties']['country_code']) {
    self.setup.country = features['properties']['country_code'];
  }

  if(features['properties']['address_line2']) {
      self.setup.address_2 = features['properties']['address_line2'];
  }

  if(features['properties']['address_level2']) {
    self.setup.city = features['properties']['address_level2'];
  }

  if(features['properties']['address_level1']) {
     self.setup.state = features['properties']['address_level1'];
  }

  if(features['properties']['postcode']) {
      self.setup.zip = features['properties']['postcode'];
  }

  if(features['properties']['country']) {
    self.setup.country = features['properties']['country'];
  }

});

},

setTimezone() {

  this.setup.timezone = this.$moment.tz.guess();

  if(!this.timezones || this.timezones.length == 0) {
    return;
  }

  //if not in timezones by the value of "key", add it
  let timezones = this.timezones.filter(timezone => timezone.key == this.setup.timezone);

  if(timezones.length == 0) {
    this.timezones.push({key: this.setup.timezone, label: this.setup.timezone});
  }
},


}

}
</script>