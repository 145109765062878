<template>
    <div>
    
    <page-tabs page="setup" />
    
          <div class="row">
            <div class="col-6 mx-auto">
              <div class="card">
                <div class="card-body">
    

                  <template v-if="brand && brand.referenceId">

                    <h1>Carrier Registration Status</h1>

                    <template v-if="brand.brandId">
                        <p>Brand ID: {{ brand.brandId }}</p>
                    </template>

                    <template v-if="brand.companyName">
                        <p>Entity Name: {{ brand.companyName }}</p>
                    </template>

                    <template v-if="brand.entityType">
                        <p>Entity Type: {{ brand.entityType }}</p>
                    </template>

                    <template v-if="brand.identityStatus">
                        <p>Verification Status: {{ brand.identityStatus | rmDashes }}</p>
                    </template>

                    <template v-if="brand.optionalAttributes && brand.optionalAttributes.governmentEntity">
                        <p>Government Entity: {{ brand.optionalAttributes.governmentEntity }}</p>
                    </template>

                    <template v-else>
                        <p class="text-danger">Not a government entity</p>
                    </template>

                    <h2>Campaigns</h2>

                    <template v-if="campaigns && campaigns.length > 0">

                    <template v-for="campaign in campaigns">

                        <p class="card-title h4">Campaign ID {{ campaign.campaignId }}</p>
                        <p>Status {{ campaign.status }}</p>
                        <p>Use Case {{ campaign.usecase }} 
                            <template v-if="campaign.subUsecases && campaign.subUsecases.length > 0">
                                <template v-for="subUsecase in campaign.subUsecases">
                                    <span class="badge badge-pill badge-primary">{{ subUsecase }}</span>
                                </template>
                            </template>
                        </p>
                        <p>Description {{ campaign.description }}</p>

                        <hr>

                    </template>

                    </template>

                    </template>

                    <template v-if="!brand || !brand.referenceId">

                    <h1>Carrier Registration Incomplete</h1>

                    <p class="lead">Please fill out the form below to register with US telecom carriers.</p>

                    <p>This is required to send and receive texts and calls through the platform.</p>

                    <form @submit.prevent="postForm">

                    <div class="form-group">
                        <label for="name">Entity Name</label>
                        <input id="name" type="text" class="form-control" v-model="form.name" required>
                        <p class="help-block">Must be the same name associated with your EIN.</p>
                    </div>

                    <div class="form-group">
                        <label for="tax_id">EIN</label>
                        <input id="tax_id" type="text" class="form-control" v-model="form.tax_id" required>
                    </div>
                    
                    <div class="form-group">
                        <label for="website">Website</label>
                        <input id="website" type="text" class="form-control" v-model="form.website" required>
                    </div>

                    <div class="form-group">
                        <label for="address_1">Address</label>
                        <input id="address_1" type="text" class="form-control" v-model="form.address_1" required>
                    </div>

                    <div class="form-group">
                        <label for="address_2">Address 2</label>
                        <input id="address_2" type="text" class="form-control" v-model="form.address_2">
                    </div>

                    <div class="form-group">
                        <label for="city">City</label>
                        <input id="city" type="text" class="form-control" v-model="form.city" required>
                    </div>

                    <div class="form-group">
                        <label for="state">State</label>
                        <select
                            id="state"
                            v-model="form.state"
                            class="form-control"
                            :disabled="processing"
                            required
                        >
                            <template v-if="states && states.length > 0">
                                <template v-for="state in states">
                                    <option
                                        :value="state.key"
                                    >
                                        {{ state.label }}
                                    </option>
                                </template>
                            </template>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="zip">Zip</label>
                        <input id="zip" type="text" class="form-control" v-model="form.zip" required>
                    </div>

                    <div class="form-group">
                        <label for="first_name">Contact First Name</label>
                        <input id="first_name" type="text" class="form-control" v-model="form.first_name" required>
                    </div>

                    <div class="form-group">
                        <label for="last_name">Contact Last Name</label>
                        <input id="last_name" type="text" class="form-control" v-model="form.last_name" required>
                    </div>

                    <div class="form-group">
                        <label for="email">Contact Email</label>
                        <input id="email" type="email" class="form-control" v-model="form.email" required>
                    </div>

                    <div class="form-group">
                        <label for="phone">Contact Phone</label>
                        <input id="phone" type="text" class="form-control" v-model="form.phone" required>
                    </div>

                    <button type="submit" class="btn btn-primary" :disabled="processing">
                        <template v-if="processing">
                            <i class="fa fa-spinner fa-spin"></i> Processing
                        </template>
                        <template v-else>
                            Register
                        </template>
                    </button>

                    </form>

                    </template>

                    <p><a href="https://ecourtdate.com/carrier-registration" class="btn btn-primary mt-3" target="_blank">Learn more about carrier registration</a></p>

    
                </div><!--Body-->
            </div><!--Card-->
    
            </div><!--Col-->
        </div><!--Row-->

    </div>
    </template>
    <script>    
    export default {
    
        data() {
            return {
              processing: false,
              form: {},
              customer: {},
              brand: {},
              campaigns: [],
              states: []
            }
        },
        
        created () {
            this.getBrand();
            this.getCustomer();
            this.getStates();
        },
    
        methods: {
    
          getBrand() {
            this.customer = {};
            this.processing = true;
            this.$http.get('/brands')
                .then(response => {
                  if(response && response.data) {
                    this.brand = response.data;
                    this.processing = false;

                    if(this.brand && this.brand.referenceId) {
                      this.getCampaigns();
                    }
                  }
                })
          },

          getCustomer() {
            this.customer = {};
            this.processing = true;
            this.$http.get('/agencies/customer')
                .then(response => {
                  if(response && response.data) {
                    this.customer = response.data;
                    this.processing = false;

                    var fields = ['name', 'tax_id', 'address_1', 'address_2', 'city', 'state', 'zip', 'website'];

                    fields.forEach(field => {
                      if(this.customer[field]) {
                        this.form[field] = this.customer[field];
                      }
                    });
                  }
                })
          },

          getCampaigns() {
            this.processing = true;
            this.$http.get('/campaigns')
                .then(response => {
                  if(response && response.data) {
                    this.campaigns = response.data;
                    this.processing = false;
                  }
                })
          },

          getStates() {
            this.$axios.get(this.$root.assets_url+'js/states.json')
            .then(response => {
                if(response && response.data) {
                    this.states = response.data;
                }
            })
        },

        postForm() {
            this.processing = true;

            this.$http.post('/brands', this.form)
                .then(response => {
                  if(response && response.data) {
                    this.brand = response.data;
                    this.processing = false;
                  }
                }
            )},
        
        }
    }
    </script>