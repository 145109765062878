<template>
  <div>

    <page-tabs :page="$route.meta.base_url" />

    <div class="container">

    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h1>General Settings</h1>
      </div>
      <div class="col-sm-12 col-md-6 text-right">
        <button
          id="settingsForm"
          type="submit"
          class="btn btn-success"
          :disabled="processing"
          @click="postForm()"
        >
          Save
        </button>
      </div>
    </div>
    <div class="row mt-3">

      <div class="col-12">
          <form
            ref="settingsForm"
            role="form"
            @submit.prevent="postForm()"
          >
            <div class="card">
              <div class="card-body">

                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="searchSettings"><i class="fa fa-search"></i> Search</span>
                  </div>
                  <input type="search" class="form-control" aria-label="Search Settings" aria-describedby="searchSettings" v-model="search_settings">
                </div>
                
                <div class="form-group" v-show="showSearch('Send Mode')">
                  <label for="send_mode">Send Mode</label>
                  <select
                    id="send_mode"
                    v-model="form.send_mode"
                    class="form-control text-capitalize"
                    :disabled="processing"
                    required
                  >
                    <option
                      v-for="send_mode in send_modes"
                      :value="send_mode.key"
                    >
                      {{ send_mode.title }}
                    </option>
                  </select>
                </div>

                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group" v-show="showSearch('Agency Name')">
                      <label for="name">Agency Name <merge-tag :merge_tag="'[AgencyName]'" /></label>
                      <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        class="form-control"
                        required
                      >
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group" v-show="showSearch('Agency Reference')">
                      <label for="reference">Agency Reference</label>
                      <input
                        id="reference"
                        v-model="form.reference"
                        type="text"
                        class="form-control"
                        :disabled="!superAdmin"
                        required
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-4">
                    <div class="form-group" v-show="showSearch('Agency Phone')">
                      <label for="phone">Agency Phone <merge-tag :merge_tag="'[AgencyPhone]'" /></label>
                      <input id="phone" type="text" class="form-control" v-model="form.phone" :disabled="processing">
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                    <div class="form-group" v-show="showSearch('Agency Email')">
                      <label for="email">Agency Email <merge-tag :merge_tag="'[AgencyEmail]'" /></label>
                      <input id="email" type="text" class="form-control" v-model="form.email" :disabled="processing">
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                    <div class="form-group" v-show="showSearch('Agency Website')">
                      <label for="website">Agency Website <merge-tag :merge_tag="'[AgencyWebsite]'" /></label>
                      <input
                        id="website"
                        v-model="form.website"
                        type="url"
                        class="form-control"
                      >
                    </div>
                  </div>
                </div>

                <div class="form-group" v-show="showSearch('Agency Address')">
                  <label for="agency_address">Agency Address <merge-tag :merge_tag="'[AgencyAddress]'" /></label>
                  <input
                    id="agency_address"
                    v-model="form.address"
                    type="text"
                    class="form-control"
                  >
                </div>

                <div class="form-group" v-show="showSearch('Agency Address 2')">
                  <label for="agency_address_2">Address 2 <merge-tag :merge_tag="'[AgencyAddress2]'" /></label>
                  <input
                    id="agency_address_2"
                    v-model="form.address_2"
                    type="text"
                    class="form-control"
                  >
                </div>

                <div class="row">

                  <div class="col-sm-12 col-md-6">

                    <div class="form-group" v-show="showSearch('Agency City')">
                      <label for="agency_city">City <merge-tag :merge_tag="'[AgencyCity]'" /></label>
                      <input
                        id="agency_city"
                        v-model="form.city"
                        type="text"
                        class="form-control"
                      >
                    </div>

                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6">

                  <div class="form-group" v-show="showSearch('Agency State')">
                    <label for="agency_state">State <merge-tag :merge_tag="'[AgencyState]'" /></label>
                    <select
                      id="agency_state"
                      v-model="form.state"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option value="" />
                      <option
                        v-for="state in states"
                        :value="state.key"
                      >
                        {{ state.label }}
                      </option>
                    </select>
                  </div>

                  </div><!--Col-->

                </div><!--Row-->


                <div class="row">

                  <div class="col-sm-12 col-md-6">

                    <div class="form-group" v-show="showSearch('Agency Zip')">
                      <label for="agency_zip">Zip <merge-tag :merge_tag="'[AgencyZip]'" /></label>
                      <input
                        id="agency_zip"
                        v-model="form.zip"
                        type="text"
                        class="form-control"
                      >
                    </div>

                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6">

                    <div class="form-group" v-show="showSearch('Agency County')">
                      <label for="agency_county">County <merge-tag :merge_tag="'[AgencyCounty]'" /></label>
                      <input
                        id="agency_county"
                        v-model="form.county"
                        type="text"
                        class="form-control"
                      >
                    </div>

                  </div><!--Col-->

                </div><!--Row-->

                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group" v-show="showSearch('Agency Latitude')">
                      <label for="latitude">Latitude</label>
                      <input
                          id="latitude"
                          v-model="form.latitude"
                          type="text"
                          class="form-control"
                          :disabled="processing"
                      >
                    </div>
                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6">
                    <div class="form-group" v-show="showSearch('Agency Longitude')">
                      <label for="longitude">Longitude</label>
                      <input
                          id="longitude"
                          v-model="form.longitude"
                          type="text"
                          class="form-control"
                          :disabled="processing"
                      >
                    </div>
                  </div><!--Col-->

                </div><!--Row-->

                <div class="form-group" v-show="showSearch('Time Zone')">
                  <label for="timezone">Time Zone <merge-tag :merge_tag="'[TimeZone]'" /></label>
                  <select
                    id="timezone"
                    v-model="form.timezone"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value=""></option>
                    <option
                      v-for="timezone in timezones"
                      :value="timezone.key"
                    >
                      {{ timezone.label }}
                    </option>
                  </select>
                </div>

                <div class="form-group" v-show="showSearch('Date Format')">
                  <label for="date_format">Date Format</label>
                  <select
                    id="date_format"
                    v-model="form.date_format"
                    class="form-control"
                    :disabled="processing"
                  >
                  <option value=""></option>
                    <option
                      v-for="date_format in date_formats"
                      :value="date_format.key"
                    >
                      {{ date_format.label }}
                    </option>
                  </select>
                </div>

                <div class="form-group" v-show="showSearch('Time Format')">
                  <label for="time_format">Time Format</label>
                  <select
                    id="time_format"
                    v-model="form.time_format"
                    class="form-control"
                    :disabled="processing"
                  >
                  <option value=""></option>
                    <option
                      v-for="time_format in time_formats"
                      :value="time_format.key"
                    >
                      {{ time_format.label }}
                    </option>
                  </select>
                </div>


                <template v-if="showSearch('Languages')">


                  <div class="form-group">
                    <label for="default_language">Default Language</label>
                    <select id="default_language" class="form-control" v-model="form.default_language" :disabled="processing">
                      <option value=""></option>
                      <option v-for="language in languages" :value="language.key">{{ language.label }}</option>
                    </select>
                  </div>


                <div class="form-group">
                  <label for="languages">Languages 
                    <template v-if="form.languages && form.languages.length">
                      <span class="badge badge-primary badge-pill">{{ form.languages.length }}</span>
                    </template>
                  </label>
                  <multi-select
                    id="languages"
                    v-model="form.languages"
                    placeholder="Choose languages"
                    :close-on-select="false"
                    :multiple="true"
                    :options="languages"
                    label="label"
                    track-by="label"
                  />
              </div>


              </template>

              <div class="btn-group" v-show="showSearch('Languages')">
                  <button
                    type="button"
                    class="btn btn-outline-success"
                    @click="addLanguages"
                    :disabled="processing"
                  >
                    Add All
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    @click="removeLanguages"
                    :disabled="processing"
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="setDefaultLanguages"
                    :disabled="processing"
                  >
                    Set Default
                  </button>
                </div><!--Group-->

                <div class="row my-3">

                  <div class="col-sm-12 col-md-6">

                    <template v-if="showSearch('Logo') || showSearch('Icon')">

                    <div class="form-group">
                      <label for="logo">Logo URL</label>
                      <input id="logo" type="text" class="form-control" v-model="form.logo">
                    </div>

                    <div class="form-group">
                    
                      <div class="input-group">
                        <div class="custom-file">
                          <input
                              id="logoUpload"
                              ref="logo"
                              type="file"
                              class="custom-file-input"
                              aria-describedby="logoUpload"
                              accept="image/*"
                              @change="storeLogo()"
                              :disabled="processing"
                          >
                          <label
                              class="custom-file-label"
                              for="logoUpload"
                          >
                            Upload Logo
                          </label>
                        </div>
                        <p class="form-text text-muted ml-2">Recommended size: 160x40</p>
                      </div>
                    
                    </div>

                    </template>

                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6">

                    <template v-if="showSearch('Logo') || showSearch('Icon')">

                    <div class="form-group">
                      <label for="icon">Icon URL</label>
                      <input id="icon" type="text" class="form-control" v-model="form.icon">
                    </div>

                  <div class="input-group">
                    <div class="custom-file">
                      <input
                          id="iconUpload"
                          ref="icon"
                          type="file"
                          class="custom-file-input"
                          aria-describedby="iconUpload"
                          accept="image/*"
                          @change="storeIcon()"
                          :disabled="processing"
                      >
                      <label
                          class="custom-file-label"
                          for="iconUpload"
                      >
                        Upload Icon
                      </label>
                    </div>
                    <p class="form-text text-muted ml-2">Recommended size: 40x40</p>
                  </div>

                  </template>

                  </div><!--Col-->

                </div><!--Row-->
                                
                <div class="mt-3 mt-md-0" v-show="showSearch('Business Hours')">
                  <p class="m-0 lead">Business Hours</p>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group vbTimePicker">
                        <label for="business_from_time">From Time</label>
                        <input
                          id="business_from_time"
                          v-model="form.business_from_time"
                          placeholder="hh:mm AM"
                          type="time"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group vbTimePicker">
                        <label for="business_to_time">To Time</label>
                        <input
                          id="business_to_time"
                          v-model="form.business_to_time"
                          placeholder="hh:mm AM"
                          type="time"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="btn-group mb-1" v-show="showSearch('Business Hours')">
                  <button type="button" class="btn btn-outline-primary" @click="setDefaultBusinessHours">Set Default</button>
                  <button type="button" class="btn btn-outline-danger" @click="resetBusinessHours">Reset</button>
                </div>

                <div class="row" v-show="showSearch('Business Days')">
                  <div class="col-12">

                <p class="lead mt-3 mb-0">Business Days</p>

                <div class="form-group">
                  <label for="business_days">Enabled Business Days</label>
                  <multi-select 
                    id="business_days"
                    v-model="form.business_days" 
                    :options="business_days"
                    :multiple="true"
                    :close-on-select="false"
                  />
                </div>

                <div class="btn-group mb-3">
                  <button
                    type="button"
                    class="btn btn-outline-success"
                    @click="addBusinessDays"
                    :disabled="processing"
                  >
                    Add All
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    @click="removeBusinessDays"
                    :disabled="processing"
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="setDefaultBusinessDays"
                    :disabled="processing"
                  >
                    Set Default
                  </button>
                </div><!--Group-->

                </div><!--Col-->
              </div><!--Row-->

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group" v-show="showSearch('Footer Text')">
                      <label for="footer_text">Footer Text</label>
                      <input
                        id="footer_text"
                        v-model="form.footer_text"
                        type="text"
                        class="form-control"
                      >
                    </div>
                  </div><!--Col-->

                  <div class="col-md-6">
                    <div class="form-group" v-show="showSearch('Internal Help Desk')">
                      <label for="help_desk">Internal Help Desk</label>
                      <input
                        id="help_desk"
                        v-model="form.help_desk"
                        type="text"
                        class="form-control"
                        placeholder="help@court.gov"
                      >
                    </div>
                  </div><!--Col-->
                </div><!--Row-->


                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group" v-show="showSearch('Default Message Delay')">
                      <label for="message_delay">Default Message Delay</label>
                      <input
                        id="message_delay"
                        v-model="form.message_delay"
                        type="number"
                        class="form-control"
                        min="0"
                        step="1"
                      >
                      <small class="form-text text-muted">In minutes. Defaults to 5.</small>
                    </div>

                    <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Send Immediate')"
                    >
                      <input
                        id="send_immediate"
                        v-model="form.send_immediate"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="send_immediate"
                      > Send Immediately</label>
                      <template v-if="form.send_immediate">
                      <small class="form-text text-muted">
                        Outbound messages will skip the dispatch queue and be sent immediately.
                      </small>
                    </template>
                    </div>

                  </div><!--Col-->
                  
                </div><!--Row-->

                  <div class="form-group" v-show="showSearch('Default Message Subject')">
                    <label for="default_subject">Default Message Subject</label>
                    <input
                      id="default_subject"
                      v-model="form.default_subject"
                      type="text"
                      class="form-control"
                    >
                    <small class="form-text text-muted">Default subject for outbound messages. Supports merge tags.</small>
                  </div>

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group" v-show="showSearch('Waitlist Cutoff')">
                      <label for="waitlist_cutoff">Waitlist Cutoff</label>
                      <input
                        id="waitlist_cutoff"
                        v-model="form.waitlist_cutoff"
                        type="number"
                        class="form-control"
                      >
                      <small class="form-text text-muted">In hours.</small>
                    </div>
                  </div><!--Col-->

                  <div class="col-sm-6" v-show="showSearch('Issues Emails')">
                    <div class="form-group">
                      <label for="issues_email">Issues Emails</label>
                      <input
                        id="issues_email"
                        v-model="form.issues_email"
                        type="text"
                        class="form-control"
                      >
                    </div>
                    <small class="form-text text-muted">Comma-separated list of emails</small>
                  </div><!--Col-->
                </div><!--Row-->

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group" v-show="showSearch('Client Display Name')">
                      <label for="client_display">Client Display Name</label>
                      <select
                        id="client_display"
                        v-model="form.client_display"
                        class="form-control"
                      >
                        <option value="" />
                        <option
                          v-for="display in options.client_displays"
                          :value="display.key"
                        >
                          {{ display.label }}
                        </option>
                      </select>
                    </div>
                  </div><!--Col-->


                  <div class="col-sm-6">
                    <div class="form-group" v-show="showSearch('Client Required Fields')">
                      <label for="client_required_fields">Add Client Required Fields</label><br>
                      <multi-select 
                        id="client_required_fields"
                        v-model="form.required_fields" 
                        :options="options.required_fields"
                        label="label"
                        track-by="key"
                        :multiple="true"
                        :close-on-select="false"
                      />
                    </div>
                  </div><!--Col-->
                </div><!--Row-->


                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group" v-show="showSearch('Opt out Keywords')" :hidden="form.disable_optout">
                      <label for="optout_keywords">Opt-out Keywords</label>
                      <input
                        id="optout_keywords"
                        v-model="form.optout_keywords"
                        type="text"
                        class="form-control"
                      >
                      <small class="form-text text-muted">Comma-separated. Case insensitive. Default: stop, unsubscribe, cancel, quit, wrong number</small>
                    </div>

                    <div class="form-group custom-control custom-switch mt-3"
                    v-show="showSearch('Disable Optout')"
                    >
                      <input
                        id="disable_optout"
                        v-model="form.disable_optout"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="disable_optout"
                      > Disable Opt-out by Keywords</label>
                    </div>

                  </div><!--Col-->

                  <div class="col-sm-6">
                    <div class="form-group" v-show="showSearch('Client Duplicates')">
                      <label for="client_duplicates">Client Duplicates</label><br>
                      <multi-select 
                        id="client_duplicates"
                        v-model="form.client_duplicates" 
                        :options="options.required_fields"
                        label="label"
                        track-by="key"
                        :multiple="true"
                        :close-on-select="false"
                      />
                    </div>
                  </div><!--Col-->
                </div><!--Row-->

                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group" v-show="showSearch('Default Payment Due Date')">
                      <label for="payment_due_date">Default Payment Due Date</label>
                      <input
                        id="payment_due_date"
                        v-model="form.payment_due_date"
                        type="number"
                        class="form-control"
                      >
                      <small class="form-text text-muted">In days. Defaults to 30.</small>
                    </div>

                    <div class="form-group" v-show="showSearch('Default Payment Status')">
                      <label for="payment_status">Default Payment Status</label>
                      <input id="payment_status" class="form-control" type="text" v-model="form.payment_status">
                      <small class="form-text text-muted">Defaults to draft.</small>
                    </div>


                    <div class="form-group" v-show="showSearch('Default Payment Type')">
                      <label for="payment_type">Default Payment Type</label>
                      <input id="payment_type" class="form-control" type="text" v-model="form.payment_type">
                    </div>


                      <div class="form-group" v-show="showSearch('Auto Update Payment Status')">
                        <label for="auto_update_payment_status">Auto Update Payment Status</label>
                        <input
                          id="auto_update_payment_status"
                          v-model="form.auto_update_payment_status"
                          type="number"
                          class="form-control"
                        >
                        <small class="form-text text-muted">In days. Defaults to 30.</small>
                      </div>

                      <div class="form-group" v-show="showSearch('Auto Update Payment Status')">
                        <label for="auto_update_payment_status_from">Auto Update Payment Status From</label>
                        <input
                          id="auto_update_payment_status_from"
                          v-model="form.auto_update_payment_status_from"
                          type="text"
                          class="form-control"
                        >
                      </div>

                      <div class="form-group" v-show="showSearch('Auto Update Payment Status')">
                        <label for="auto_update_payment_status_to">Auto Update Payment Status To</label>
                        <input
                          id="auto_update_payment_status_to"
                          v-model="form.auto_update_payment_status_to"
                          type="text"
                          class="form-control"
                        >
                      </div>

                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6">
                    <div class="form-group" v-show="showSearch('Default Event Duration')">
                      <label for="event_duration">Default Event Duration</label>
                      <input
                        id="event_duration"
                        v-model="form.event_duration"
                        type="number"
                        class="form-control"
                      >
                      <small class="form-text text-muted">In minutes. Defaults to 60.</small>
                    </div>

                  </div><!--Col-->
                </div><!--Row-->

                     <div class="form-group" v-show="showSearch('Default File Due Date')">
                      <label for="file_due_date">Default Sent File Due Date</label>
                      <input
                        id="file_due_date"
                        v-model="form.file_due_date"
                        type="number"
                        class="form-control"
                      >
                      <small class="form-text text-muted">In days. Defaults to 30.</small>
                    </div>

                <div class="form-group" v-show="showSearch('Case Management System CMS URL')">
                  <label for="cms_url">Case Management System URL</label>
                  <input
                    id="cms_url"
                    v-model="form.cms_url"
                    class="form-control"
                  >
                </div>

                <div class="form-group" v-show="showSearch('Case Types')">
                  <label for="case_types">Case Types</label>
                  <input
                    id="case_types"
                    v-model="form.case_types"
                    class="form-control"
                  >
                  <small class="form-text text-muted">Comma-separated list of case types</small>
                </div>

                <div class="form-group" v-show="showSearch('Document Types')">
                  <label for="document_types">Document Types</label>
                  <input
                    id="document_types"
                    v-model="form.document_types"
                    class="form-control"
                  >
                  <small class="form-text text-muted">Comma-separated list of documents that clients may upload.</small>
                </div>

                <div class="row">
                  <div class="col-4">
                    <div class="form-group" v-show="showSearch('Allow Transfers From')">
                      <label for="allow_transfers_from">Allow Transfers From</label>
                      <input
                        id="allow_transfers_from"
                        v-model="form.allow_transfers_from"
                        class="form-control"
                      >
                      <small class="form-text text-muted">Comma-separated agency UUIDs</small>
                    </div>
                  </div><!--Col-->

                  <div class="col-4">
                    <div class="form-group" v-show="showSearch('Allow Transfers To')">
                      <label for="allow_transfers_to">Allow Transfers To</label>
                      <input
                        id="allow_transfers_to"
                        v-model="form.allow_transfers_to"
                        class="form-control"
                      >
                      <small class="form-text text-muted">Comma-separated agency UUIDs</small>
                    </div>
                  </div><!--Col-->

                  <div class="col-4">
                    <div class="form-group" v-show="showSearch('Notify on Transfers')">
                      <label for="transfers_notify">Notify on Transfers</label>
                      <input
                        id="transfers_notify"
                        v-model="form.transfers_notify"
                        class="form-control"
                      >
                      <small class="form-text text-muted">Comma-separated emails and phones</small>
                    </div>
                  </div><!--Col-->
                </div><!--Row-->

                <div class="row">
                  <div class="col-sm-12 col-lg-6">
                    <div class="form-group" v-show="showSearch('Notify on Geolocation Denied')">
                      <label for="geo_denied_notify">Notify on Geolocation Denied</label>
                      <input
                        id="geo_denied_notify"
                        v-model="form.geo_denied_notify"
                        class="form-control"
                      >
                      <small class="form-text text-muted">Comma-separated emails and phones</small>
                    </div>
                  </div><!--Col-->

                  <div class="col-sm-12 col-lg-6">
                    <div class="form-group" v-show="showSearch('Default Bulk Actions Limit')">
                      <label for="default_bulk_actions_limit">Default Bulk Actions Limit</label>
                      <input
                        id="default_bulk_actions_limit"
                        v-model="form.default_bulk_actions_limit"
                        type="number"
                        class="form-control"
                      >
                      <small class="form-text text-muted">Defaults to 250</small>
                    </div>
                  </div><!--Col-->

                </div><!--Row-->

                <div class="row">

                  <div class="col-sm-12 col-lg-6">
                    <div class="form-group" v-show="showSearch('Auto Trash Archived Data')">
                      <label for="auto_trash_archived">Auto Trash Archived Data</label>
                      <input
                        id="auto_trash_archived"
                        v-model="form.auto_trash_archived"
                        type="number"
                        class="form-control"
                        step="1"
                      >
                      <small class="form-text text-muted">In number of days since a record is archived. Defaults to never.</small>
                    </div>
                  </div><!--Col-->

                  <div class="col-sm-12 col-lg-6">
                    <div class="form-group" v-show="showSearch('Auto Delete Trashed Data')">
                      <label for="auto_delete_trashed">Auto Delete Trashed Data</label>
                      <input
                        id="auto_delete_trashed"
                        v-model="form.auto_delete_trashed"
                        type="number"
                        class="form-control"
                        step="1"
                        min="1"
                        max="180"
                      >
                      <small class="form-text text-muted">In number of days since a record is trashed. Defaults to 30.</small>
                    </div>
                  </div><!--Col-->

                  </div><!--Row-->

                  <div class="form-group" v-show="showSearch('Default Groups')">
                    <label for="default_groups">Default Groups</label>
                    <input id="default_groups" type="text" class="form-control" v-model="form.default_groups">
                    <small class="text-muted">Comma-separated list of groups. Example: 1, 2, 3</small>
                  </div>

                  <div class="form-group" v-show="showSearch('Primary Judge Name')">
                    <label for="default_judge">Primary Judge Name</label>
                    <input
                      id="default_judge"
                      v-model="form.default_judge"
                      type="text"
                      class="form-control"
                    >
                    <small class="text-muted">Default Judge name to use for new events if no Judge is provided.</small>
                  </div>

                  <div class="form-group" v-show="showSearch('Available Judge Names')">
                    <label for="default_judges">Available Judge Names</label>
                    <input id="default_judges" type="text" class="form-control" v-model="form.default_judges">
                    <small class="text-muted">Comma-separated list of Judge names. Example: Lincoln, George, Roberts</small>
                  </div>

                <div class="form-group" v-show="showSearch('Virtual Platform')">
                  <label for="virtual_platform">Virtual Platform</label>
                  <select id="virtual_platform" class="form-control" v-model="form.virtual_platform" :disabled="processing">
                    <option value=""></option>
                    <template v-for="virtual_platform in virtual_platforms">
                      <option :value="virtual_platform">{{ virtual_platform }}</option>
                    </template>
                  </select>
                </div>

                <div class="form-group" v-show="showSearch('Virtual Base Link')">
                  <label for="virtual_link">Virtual Base Link</label>
                  <input id="virtual_link" type="text" class="form-control" v-model="form.virtual_link">
                </div>

                <div class="row">

                  <div class="col-sm-12 col-md-4">
                    <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Geocode Addresses')">
                      <input
                        id="geocode_addresses"
                        v-model="form.geocode_addresses"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="geocode_addresses"
                      >Geocode Addresses</label>
                    </div>
                  </div><!--Col-->

                  <div class="col-sm-12 col-md-4">
                    <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Assign Clients to Creator')">
                      <input
                        id="assign_clients_creator"
                        v-model="form.assign_clients_creator"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="assign_clients_creator"
                      >Assign Clients to Creator</label>
                    </div>

                    <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Filter Data by Assigned Client')">
                      <input
                        id="filter_clients_data"
                        v-model="form.filter_clients_data"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="filter_clients_data"
                      >Filter Data by Assigned Clients</label>
                    </div>
                  </div><!--Col-->

                  <div class="col-sm-12 col-md-4">
                    <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Disable Weekend Events')">
                      <input
                        id="disable_weekend_events"
                        v-model="form.disable_weekend_events"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="disable_weekend_events"
                      >Disable Messages for Weekend Events</label>
                    </div>

                    <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Disable Holiday Events')">
                      <input
                        id="disable_holiday_events"
                        v-model="form.disable_holiday_events"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="disable_holiday_events"
                      >Disable Messages for Holiday Events</label>
                    </div>

                    <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Disable Outside of Business Events')">
                      <input
                        id="disable_outside_business_events"
                        v-model="form.disable_outside_business_events"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="disable_outside_business_events"
                      >Disable Messages for Outside of Business Hours Events</label>
                      </div>
                  </div><!--Col-->
                </div><!--Row-->

                <div class="row">

                  <div class="col-sm-12 col-md-4">
                    <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Auto Detect Language')">
                      <input
                        id="detect_language"
                        v-model="form.detect_language"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="detect_language"
                      >Detect Language</label>
                      <p class="form-text text-muted m-0">Automatically detect the language of incoming messages.</p> 
                    </div>
                  </div><!--Col-->

                  <div class="col-sm-12 col-md-4">
                    <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Auto Generate Client Reference')">
                      <input
                        id="auto_client_reference"
                        v-model="form.auto_client_reference"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="auto_client_reference"
                      >Auto Generate Client Reference</label>
                    </div>

                    <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Auto Generate Case Number')">
                      <input
                        id="auto_case_number"
                        v-model="form.auto_case_number"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="auto_case_number"
                      >Auto Generate Case Number</label>
                    </div>

                    <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Auto Run Lookups')">
                      <input
                          id="auto_lookups"
                          v-model="form.auto_lookups"
                          type="checkbox"
                          class="custom-control-input"
                      >
                      <label
                          class="custom-control-label"
                          for="auto_lookups"
                      >Auto Run Lookups</label>
                    </div>

                    <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Auto Replace Links')">
                      <input
                          id="auto_replace_links"
                          v-model="form.auto_replace_links"
                          type="checkbox"
                          class="custom-control-input"
                      >
                      <label
                          class="custom-control-label"
                          for="auto_replace_links"
                      >Auto Replace Links</label>
                    </div>

                    <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Auto Retry Failures')">
                      <input
                          id="auto_retry_failures"
                          v-model="form.auto_retry_failures"
                          type="checkbox"
                          class="custom-control-input"
                      >
                      <label
                          class="custom-control-label"
                          for="auto_retry_failures"
                      >Auto Retry Failures</label>
                    </div>

                    <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Auto Create Contacts')">
                      <input
                          id="auto_create_contacts"
                          v-model="form.auto_create_contacts"
                          type="checkbox"
                          class="custom-control-input"
                      >
                      <label
                          class="custom-control-label"
                          for="auto_create_contacts"
                      >Auto Create Contacts</label>
                    </div>

                  </div><!--Col-->

                </div><!--Row-->

                <div class="row">

                  <div class="col-sm-12 col-md-4">

                    <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Enable Portal chat')">
                      <input
                          id="enable_chat"
                          v-model="form.enable_chat"
                          type="checkbox"
                          class="custom-control-input"
                      >
                      <label
                          class="custom-control-label"
                          for="enable_chat"
                      >Enable Portal Chat</label>
                      <p class="small text-muted">Allow authorized users to chat with clients on a web portal.</p>
                    </div>

                  </div><!--Col-->

                  <div class="col-sm-12 col-md-4">

                  </div><!--Col-->

                </div><!--Row-->

                <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Enable Checkins')">
                  <input
                      id="enable_checkins"
                      v-model="form.enable_checkins"
                      type="checkbox"
                      class="custom-control-input"
                  >
                  <label
                      class="custom-control-label"
                      for="enable_checkins"
                  >Enable Event Check-ins</label>
                  <p class="form-text text-muted">Allow check-ins directly from an event portal</p>
                </div>

                <template v-if="form.enable_checkins">

                  <div class="row" v-show="showSearch('Enable Checkins')">
                    <div class="col-sm-12 col-md-6">
                      <div class="form-group">
                        <label for="checkins_earliest">Earliest Check-in</label>
                        <input id="checkins_earliest" type="number" class="form-control" v-model="form.checkins_earliest">
                        <p class="form-text text-muted">The earliest checkin allowed for a scheduled event. Defaults to 24 hours prior the scheduled event date.</p>
                      </div>
                    </div><!--Col-->

                    <div class="col-sm-12 col-md-6">
                      <div class="form-group">
                        <label for="checkins_latest">Latest Check-in</label>
                        <input id="checkins_latest" type="number" class="form-control" v-model="form.checkins_latest">
                        <p class="form-text text-muted">The earliest checkin allowed for a scheduled event. Defaults to 24 hours after the scheduled event date.</p>
                      </div>
                    </div><!--Col-->
                  </div>

                </template>

                <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Merge Locations')">
                      <input
                          id="merge_locations"
                          v-model="form.merge_locations"
                          type="checkbox"
                          class="custom-control-input"
                      >
                      <label
                          class="custom-control-label"
                          for="merge_locations"
                      >Merge Locations in Messages</label>
                    </div>

                    <div class="form-group custom-control custom-switch mt-3" v-show="showSearch('Map Agencies to Locations')">
                      <input
                          id="map_agency_location"
                          v-model="form.map_agency_location"
                          type="checkbox"
                          class="custom-control-input"
                      >
                      <label
                          class="custom-control-label"
                          for="map_agency_location"
                      >Map Agencies to Locations</label>
                    </div>

                      <div class="form-group" v-show="showSearch('Client Tabs')">

                        <h4>Customize Client Tabs</h4>

                        <form @submit.prevent="addTab()">

                        <div class="form-group">
                          <label for="tab_name">Tab Name</label>
                          <input id="tab_name" type="text" class="form-control" v-model="tabForm.name">
                        </div>

                        <div class="form-group">
                          <label for="tab_link">Tab Link</label>
                          <input id="tab_link" type="text" class="form-control" v-model="tabForm.link">
                        </div>

                        <button type="submit" class="btn btn-success" :disabled="processing">Add Tab</button>

                        <button type="button" class="btn btn-outline-secondary" @click="setDefaultTabs()" :disabled="processing">Set Default</button>

                        <button type="button" class="btn btn-outline-danger" @click="resetTabs()" :disabled="processing">Reset</button>

                        </form>

                        <template v-if="form.client_tabs && form.client_tabs.length">

                          <div class="list-group my-3">
                            <template v-for="(tab, tab_index) in form.client_tabs">
                              <div class="list-group-item d-flex justify-content-between align-items-center">

                                <div class="form-group">
                                  <label for="tab_name">Tab Name</label>
                                  <input type="text" class="form-control" v-model="form.client_tabs[tab_index].name">
                                </div>

                                <div class="form-group">
                                  <label for="tab_link">Tab Link</label>
                                  <input type="text" class="form-control" v-model="form.client_tabs[tab_index].link">
                                </div>

                                <button type="button" class="btn btn-danger btn-sm" @click="removeTab(tab_index)">Remove</button>
                              </div>
                            </template>
                          </div>

                        </template>

                      </div>


                <section v-show="showSearch('Customize Data Labels')">

                  <h4>Customize Data Labels</h4>

                  <template v-for="label in labels">

                    <div class="form-group">
                      <label :for="'label_'+label">Label for <span class="text-lowercase">{{ label }}</span></label>
                      <input :id="'label_'+label" type="text" class="form-control" v-model="form.labels[label]">
                    </div>

                  </template>

                </section>

                <section v-show="showSearch('Customize Fields')">

                <h4>Customize Fields</h4>

                <div class="row">

                  <div class="col-sm-12 col-md-6">

                    <div class="form-group">
                      <label for="choose_custom_field">Choose Field</label>
                      <select id="choose_custom_field" class="form-control" v-model="customField" @change="addCustomField()" :disabled="processing">
                        <template v-for="(field_option, option_index) in fields">
                          <option :value="option_index">{{ field_option.name }}</option>
                        </template>
                      </select>
                    </div>

                    <template v-for="(custom_field, custom_index) in form.fields">

                      <div class="form-group" :disabled="processing" required>
                        <label
                        :for="'custom_field_'+custom_field.key"
                        >{{ custom_field.key }}</label>
                        <input 
                        :id="'custom_field_'+custom_field.key"
                        type="text" class="form-control" 
                        v-model="form.fields[custom_index].name">
                      </div>
                    </template>

                  </div><!--Col-->

                </div><!--Row-->

                <template v-if="form.fields && form.fields.length">

                  <button type="button" class="btn btn-outline-danger btn-sm" @click="resetCustomFields()" :disabled="processing">Reset Fields</button>

                </template>

                </section>

                <section v-show="showSearch('Calendar Settings')">

                <h4 class="mt-3">Calendar Settings</h4>

                <p>Customize the ICS file that is used to generate calendar events.</p>

                <div class="form-group">
                  <label for="calendar_title">Calendar Title</label>
                  <input id="calendar_title" type="text" class="form-control" v-model="form.calendar_title">
                </div>

                <div class="form-group">
                  <label for="calendar_description">Calendar Description</label>
                  <textarea id="calendar_description" class="form-control" v-model="form.calendar_description"></textarea>
                </div>

                </section>


                <button type="submit" class="btn btn-success my-3" :disabled="processing">Save</button>

              </div>
            </div>

          </form>

          <div class="row mt-3">
            <div class="col-sm-12 col-md-6">
              <button type="button" class="btn btn-danger" @click="resetSettings()" :disabled="processing">Reset {{  $route.meta.title }}</button>
            </div><!--Col-->

            <div class="col-sm-12 col-md-6">

              <template v-if="form && form.created_at">
                <audit-logs class="mt-3" :form="form" />
              </template>

            </div><!--Col-->

          </div>

      </div>

      </div>
    </div>
  </div>
</template>
<script>
import queries from "../mixins/queries";
import jsonParse from '../mixins/jsonParse';

export default {
    
    mixins: [ queries, jsonParse ],

    data() {
        return {
          processing: false,
          search_settings: null,
          options: {
              required_fields: []
          },
          fields: [],
          form: {},
          send_modes: [
          {key: 'simulate', title: 'send simulate'},
          {key: 'live', title: 'send live'}
          ],
          timezones: [],
          tabs: [
          {key: 'general'},
          {key: 'agency_phones'},
          {key: 'agency_emails'},
          {key: 'statuses'},
          {key: 'roles'}
          ],
          business_days: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
          date_formats: [
            {key: 'm/d/Y', label: 'MM/DD/YYYY (Default)'},
            {key: 'd/m/Y', label: 'DD/MM/YYYY'},
            {key: 'Y/m/d', label: 'YYYY/MM/DD'},
            {key: 'Y-m-d', label: 'YYYY-MM-DD'},
            {key: 'd-m-Y', label: 'DD-MM-YYYY'},
            {key: 'd.m.Y', label: 'DD.MM.YYYY'},
            {key: 'Y.m.d', label: 'YYYY.MM.DD'},
            {key: 'D, M j', label: 'Day, Month Day'},
            {key: 'D, M j, y', label: 'Day, Month Day, Year'}
          ],
          time_formats: [
            {key: 'h:i A', label: '12 Hour (Default)'},
            {key: 'H:i', label: '24 Hour'},
            {key: 'h:i:s A', label: '12 Hour with Seconds'},
            {key: 'H:i:s', label: '24 Hour with Seconds'},
            {key: 'g:i A', label: '12 Hour without leading zeros'},
            {key: 'G:i', label: '24 Hour without leading zeros'}
          ],
          client_tabs: [
            {
              name: 'Events',
              link: 'events'
            },
            {
              name: 'Cases',
              link: 'cases'
            },
            {
              name: 'Payments',
              link: 'payments'
            },
            {
              name: 'Sent Files',
              link: 'sent_files'
            },
            {
              name: 'Addresses',
              link: 'addresses'
            },
            {
              name: 'Messages',
              link: 'messages'
            },
            {
              name: 'Opens',
              link: 'opens'
            },
            {
              name: 'Comments',
              link: 'comments'
            },
            {
              name: 'Documents',
              link: 'documents'
            },
            {
              name: 'Warrants',
              link: 'warrants'
            }
          ],
          tabForm: {
            name: null,
            link: null
          },
          customField: {},
          labels: ['client', 'event', 'payment', 'case', 'warrant', 'contact', 'organization', 'judge'],
          states: [],
          default_settings: {
            send_mode: 'live',
            business_from_time: '08:00',
            business_to_time: '18:00',
            business_days: ['mon', 'tue', 'wed', 'thu', 'fri'],
            geocode_addresses: 1,
            detect_language: 1,
            auto_client_reference: 1,
            auto_case_number: 1,
            assign_clients_creator: 1,
            disable_weekend_events: 1,
            filter_clients_data: 0,
            enable_chat: 1,
            enable_checkins: 1,
            auto_lookups: 1,
            auto_replace_links: 1,
            auto_retry_failures: 1,
            auto_create_contacts: 0,
            languages: [
              {key: 'en', label: 'English'},
              {key: 'es', label: 'Español (spanish)'}
            ]
          },
          virtual_platforms: ['Zoom', 'WebEx', 'Teams', 'GoToMeeting', 'Google Meet', 'Skype', 'Other']
        }
    },

    computed: {

      superAdmin () {
          return this?.$root?.current_user?.super_admin;
      }

    },

      mounted() {

        var self = this;

        this.$eventBus.$on('settingsProcessing', function (data) {
         self.processing = data;
        });

        this.$eventBus.$on('settings', function (data) {
          self.form = {...self.form, ...data};
        });

      },

    created() {
        
        this.resetForm();
        this.setURLParams();
        this.getOptions();
        this.getFields();
        this.getStates();
        this.getSettings();
        this.getLanguages();
        this.getTimezones();
    },


    methods: {

    resetForm() {
      this.form = {
        timezone: 'UTC',
        languages: [],
        fields: [],
        labels: {},
        phone: null,
        email: null,
        website: null,
        address: null,
        address_2: null,
        city: null,
        state: null,
        county: null,
        zip: null,
        country: null,
        business_from_time: null,
        business_to_time: null,
        business_days: [],
        latitude: null,
        longitude: null,
        send_mode: null,
        virtual_link: null,
        virtual_platform: null
      }
    },
    
    setURLParams() {

    var searchParams = new URLSearchParams(window.location.search);

    var self = this;

    searchParams.forEach(function(value, key) {
      
      if(key == 'search') {
        self.search_settings = value;
      }

    });
    },

    addCustomField() {
      
      if(!this.form.fields) {
        this.form.fields = [];
      }

      var field = {};

      if(!this.fields[this.customField].name) {
        return;
      }

      field.name = this.fields[this.customField].name;
      field.key = this.fields[this.customField].key;

      this.form.fields.push(field);
    },

        getOptions () {
          this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
          .then(response => {
              if(response && response.data) {
                  this.options = response.data;
              }
          })
      },

      getFields() {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/fields.json')
        .then(response => {
          if(response && response.data) {
            this.fields = response.data;
          }
        })
      },
    
    getStates () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/states.json')
      .then(response => {
        if (response && response.data) {
          this.states = response.data;

          if(this.$root.current_user && this.$root.current_user.current_agency) {
            this.form.name = this.$root.current_user.current_agency.name;
          }
        }
      })
    },

    getSettings() {
      this.processing = true;
        this.$http.get('/'+this.$route.meta.base_url)
        .then(response => {
            if(response && response.data) {
                this.form = response.data;

                if(!this.form.labels) {
                  this.form.labels = {};
                }

                this.setTitle();

                this.getGeocodes();

                this.processing = false;
            }
        })
    },

    resetCustomFields() {
      this.form.fields = [];
    },

    resetTabs() {
      this.form.client_tabs = null;
    },

    setDefaultTabs() {
      this.form.client_tabs = this.client_tabs;
    },

    removeTab(index) {
      this.form.client_tabs.splice(index, 1);
      this.postForm();
    },

    addTab() {
      if(this.tabForm.name && this.tabForm.link) {

        if(!this.form.client_tabs) {
          this.form.client_tabs = [];
        }

        this.form.client_tabs.push(this.tabForm);
        this.tabForm = {};
      }

      this.postForm();
    },

      setTitle() {
        if(this.form.name) {
          document.title = this.form.name + ' | Agency ' + this.$route.meta.title;
        }
      },

      getGeocodes() {

      if(this.form.latitude && this.form.longitude) {
        return;
      }

      if(!this.form.address) {
        return;
      }

      var queries = {
        address: this.form.address,
        city: this.form.city,
        state: this.form.state,
        zip_code: this.form.zip
      }

        this.$http.get(this.buildQueries('/geocode', queries))
            .then(response => {
              if(response && response.data && response.data[0]) {

               var result = response.data[0];

               if(!result['geometry']['location'] ||  !result['geometry']['location']['lat'] || !result['geometry']['location']['lng']) {
                  return;
               }

               this.form.latitude = result['geometry']['location']['lat'];
               this.form.longitude = result['geometry']['location']['lng'];
               this.postForm();

              }
            })
      },

        postForm() {

            if(this.$route.query.tab && this.$route.query.tab != 'general') {
                return;
            }

            this.processing = true;

            if(this.$root.current_region == 'staging') {
                this.form.send_mode = 'simulate';
            }

            this.$http.patch('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                if(response && response.data) {

                    this.processing = false;

                    if(response.data.updated_at) {
                        this.$root.current_user.current_agency.settings = response.data;
                        localStorage.setItem('settings', JSON.stringify(response.data));
                        new this.$noty({text: this.$route.meta.title + ' updated'}).show();
                        this.form.updated_at = response.data.updated_at;
                    }

                }
            })
        },

        setSettings() {

            if(this.form.settings && this.form.settings.length > 5) {
                let settings = this.parseJSON(this.form.settings)
                this.form = {...this.form, ...settings}
                delete this.form.settings
            }

            if (this.form.business_from_time) {
                this.form.business_from_time = this.$options.filters.formatFormTime(this.form.business_from_time)
            }

            if (this.form.business_to_time) {
                this.form.business_to_time = this.$options.filters.formatFormTime(this.form.business_to_time)
            }

            if(this.form.languages) {
                this.form.languages = this.parseJSON(this.form.languages);
            }
        },

        addLanguages() {
            if(this.languages) {
                this.form.languages = this.languages;
            }
            
        },

        removeLanguages() {
            this.form.languages = []
        },

        addBusinessDays() {
            this.form.business_days = this.business_days;
        },

        removeBusinessDays() {
            this.form.business_days = []
        },

        setDefaultBusinessDays() {
            this.form.business_days = ['mon', 'tue', 'wed', 'thu', 'fri'];
        },

        setDefaultBusinessHours() {
            this.form.business_from_time = '08:00';
            this.form.business_to_time = '18:00';
        },

        resetBusinessHours() {
            this.form.business_from_time = null;
            this.form.business_to_time = null;
        },

        setDefaultLanguages() {
            this.form.languages = [
                {key: 'en', label: 'English'},
                {key: 'es', label: 'Español (spanish)'}
            ]
        },

      resetSettings() {

        this.form = {...this.form, ...this.default_settings};

        this.form.timezone = this.$moment.tz.guess();

        if(['dev', 'staging'].includes(this.$root.current_region)) {
          this.form.send_mode = 'simulate';
        }

        this.postForm();
      },


      showSearch(key) {
        if(!this.search_settings) {
          return true;
        }

        var search = this.search_settings.toLowerCase();

        if(key.toLowerCase().includes(search)) {
          return true;
        }


        return false;
      },

      storeLogo () {
            if (this?.$refs?.logo?.files?.length > 0) {
                this.processing = true;
                this.storeUpload(this.$refs.logo.files[0])
                .then(response => {
                    if (response && response.uuid) {
                        this.form.logo = this.$root.portal_assets+response.uuid;
                        this.processing = false;
                    }
                })
            }
        },

        storeIcon () {
            if (this?.$refs?.icon?.files?.length > 0) {
                this.processing = true;
                this.storeUpload(this.$refs.icon.files[0])
                .then(response => {
                    if (response && response.uuid) {
                        this.form.icon = this.$root.portal_assets+response.uuid;
                        this.processing = false;
                    }
                })
            }
        },

      async storeUpload (file) {
          const response = await this.$http.post(this.$apiURL + 'v1/signed_url', {
              'bucket': 'ecdportals',
              'content_type': file.type,
              'content_length': file.size,
              'expires': '',
              'visibility': ''
          })
          if (response && response.data) {
            
              let headers = response.data.headers;

              if ('Host' in headers) {
                  delete headers.Host
              }
              await this.$axios.put(response.data.url, file, {
                  headers: headers
              });

              response.data.extension = file.name.split('.').pop();

              return response.data;
          }
          return null;
      },
    }
}
</script>